import { useMutation, useInfiniteQuery, useQueryClient, useQueries } from "react-query";
import { Button } from "@mui/material";
import ApiContext from "./ApiContext";
import React from "react";
import _ from "lodash";
import axios from "axios";
import useConfig from "./useConfig";
import { useSnackbar } from "notistack";
import useLocalData from "./useLocalData";
import { logError } from "../utils/logError";
import useCoordinateAppConfig from "./useCoordinateAppConfig";

const TASK_BATCH_SIZE = 50;
const RPT_LIST_FIRST_KEY_VALUE = "reportLists";
const TKT_LIST_FIRST_KEY_VALUE = "ticketLists";
const RPT_LIST_LENGTH_FIRST_KEY_VALUE = "length";

// todo: on infinite scroll, how to update the total count? fix that when dealing with data flow when updating.

function getFirstKeyValue(reportListDef, entityType) {
  if (reportListDef?.type === "report" || entityType === "recommendation") {
    return RPT_LIST_FIRST_KEY_VALUE;
  } else if (reportListDef?.type === "urgentResultTicket" || entityType === "urgentResultTicket") {
    return TKT_LIST_FIRST_KEY_VALUE;
  }
}

function ReloadButton(props) {
  // using here window.location.reload() instead of react-router navigate(0) as we are out of the context of
  // react-router
  return <Button onClick={() => window.location.reload()}>Reload page</Button>;
}

function buildGetReportListQueryKey(selectedListId, reportListDef, searchParams) {
  return _.concat(
    [getFirstKeyValue(reportListDef)],
    selectedListId,
    reportListDef.requireSearchParams ? Object.fromEntries(searchParams) : []
  );
}

function buildGetReportListLengthQueryKey(selectedListId, reportListDef) {
  return _.concat(RPT_LIST_LENGTH_FIRST_KEY_VALUE, buildGetReportListQueryKey(selectedListId, reportListDef, null));
}

function buildGetReportListQueryParams(queryKey, pageParam) {
  return {
    listId: queryKey[1],
    batchSize: TASK_BATCH_SIZE,
    batchOffset: pageParam,
    ...(queryKey.length === 3 ? queryKey[2] : null),
  };
}

function pagesToFlattenRows(pages) {
  return _.flatMap(pages, (p) => p.data.tasks);
}

function updateReportInReportListData(oldPagedData, rptData, criteria) {
  return {
    ...oldPagedData,
    pages: _.map(oldPagedData.pages, (p) => ({
      ...p,
      data: {
        ...p.data,
        tasks: _.map(p.data.tasks, (oldRpt) =>
          _.get(oldRpt, criteria) === _.get(rptData, criteria) ? rptData : oldRpt
        ),
      },
    })),
  };
}

function removeReportFromReportListData(oldPagedData, rptData, criteria) {
  return {
    ...oldPagedData,
    pages: _.map(oldPagedData.pages, (p) => ({
      ...p,
      data: {
        ...p.data,
        tasks: _.filter(p.data.tasks, (r) => _.get(r, criteria) !== _.get(rptData, criteria)),
      },
    })),
  };
}

const ApiProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { reportLists } = useCoordinateAppConfig();
  const { updateProgressCounter } = useLocalData();
  const [enableListLengthQueries, setEnableListLengthQueries] = React.useState(false);

  const reportSectionsEndPoint = API + "/reports/get-sections-by-report-text-id";

  function updateReportListsWithNewPartialData(newPartialData, entityType) {
    _.forEach(newPartialData, (rpt) => {
      _.forEach(queryClient.getQueriesData([getFirstKeyValue(undefined, entityType)]), (qd) => {
        const queryKey = qd[0];
        if (!qd[1]) {
          // it could happen if data is being initially fetched while some changes is being done from another list.
          // thus, we invalidate the query and makes it re-fetch the data
          queryClient.invalidateQueries(queryKey, { exact: true });
          return;
        }
        const queryData = qd[1];
        const queryDataListId = queryKey[1];

        let criteria;
        if (entityType === "recommendation") {
          criteria = "reportTextId";
        } else if (entityType === "urgentResultTicket") {
          criteria = "urgentResults.id";
        }

        const rptExist =
          _.filter(pagesToFlattenRows(queryData.pages), (item) => {
            const itemCriteriaValue = _.get(item, criteria);
            const rptCriteriaValue = _.get(rpt, criteria);
            return itemCriteriaValue === rptCriteriaValue;
          }).length > 0;
        const rptBelongToThisList = _.includes(rpt.belongToListIds, queryDataListId);

        if (rptBelongToThisList && !rptExist) {
          // here, we don't want to insert the row, as the order might get messy, and we build upon the order while
          // fetching. In such case, re-fetch the list
          // regarding performance, the assumption here is that this isn't the current list the user is working on
          // (otherwise, from where the user got access to this report?) hence no need to immediately update
          queryClient.invalidateQueries(queryKey, { exact: true });
        } else if (rptBelongToThisList && rptExist) {
          // update the new rpt on list it was belonged and is still is
          // TODO: when we would be able to alter due date, this might be a problem (as it might get the order messy, and
          //  we build upon it when retrieving data
          queryClient.setQueryData(queryKey, (old) => updateReportInReportListData(old, rpt, criteria));
        } else if (!rptBelongToThisList && rptExist) {
          // remove the new rpt from lists it isn't belongs to anymore (except search, that isn't part of the
          // belongToListId optional values)
          if (queryDataListId !== "search") {
            queryClient.setQueryData(queryKey, (old) => removeReportFromReportListData(old, rpt, criteria));
          } else {
            // if search list, update data there
            queryClient.setQueryData(queryKey, (old) => updateReportInReportListData(old, rpt, criteria));
          }
        } else {
          // if !rptBelongToThisList && !rptExist, do nothing
        }
      });
    });
  }

  function getReportListData(selectedListId, searchParams) {
    const reportListDef = reportLists[selectedListId];
    const listType = reportListDef.type;
    const enableQuery = !!selectedListId && (!reportListDef.requireSearchParams || [...searchParams].length > 0);
    const queryKey = buildGetReportListQueryKey(selectedListId, reportListDef, searchParams);
    let endpoint, refetchInterval;
    if (listType === "report") {
      endpoint = "/tasks/get-task-list";
      refetchInterval = false;
    } else if (listType === "urgentResultTicket") {
      endpoint = "/urgent-results";
      refetchInterval = 15 * (60 * 1000);
    }
    return useInfiniteQuery(
      queryKey,
      ({ pageParam = 0 }) =>
        axios.get(API + endpoint, {
          params: buildGetReportListQueryParams(queryKey, pageParam),
          withCredentials: true,
        }),
      {
        getNextPageParam: (lastPage, allPages) => {
          let nextPageParam;
          if (!lastPage.data.isFinalBatch) {
            const rows = pagesToFlattenRows(allPages);
            nextPageParam = rows.length;
          }
          return nextPageParam;
        },
        enabled: enableQuery,
        select: (data) => {
          return pagesToFlattenRows(data.pages);
        },
        onError: (error) => {
          logError(error, { componentStack: "Error on getReportListData" });
        },
        refetchInterval,
        refetchOnWindowFocus: false,
        staleTime: 5 * (60 * 1000),
        cacheTime: 5 * (60 * 1000),
      }
    );
  }

  const reportListDataLengths = useQueries(
    _.map(
      _.pickBy(reportLists, (rl) => rl.fetchListLength),
      (listDef, listId) => {
        const queryKey = buildGetReportListLengthQueryKey(listId, listDef);
        return {
          queryKey,
          queryFn: () =>
            axios.get(API + "/tasks/get-list-length", {
              params: { listId },
              withCredentials: true,
            }),
          enabled: enableListLengthQueries,
          onError: (error) => {
            logError(error, { componentStack: "Error on reportListDataLengths" });
          },
        };
      }
    )
  );

  function getReportListDataLength(selectedListId) {
    if (!reportLists[selectedListId].fetchListLength) {
      return { noCountAvailable: true };
    } else {
      const queryKey = buildGetReportListLengthQueryKey(selectedListId, reportLists[selectedListId]);
      const queryState = queryClient.getQueryState(queryKey);
      if (queryState.isFetching) {
        return { isLoading: true };
      } else if (queryState.status === "success") {
        return {
          count: queryState.data.data.totalCount - (queryState.data.data.assignedToOthers || 0),
        };
      } else {
        // if status is not success, some problem occur while querying and no count available
        return {};
      }
    }
  }

  const onEntityMutationSuccess = (entityType, resData) => {
    // (1) update the report's data (used by the report modal):
    let data = resData.data;
    if (entityType === "recommendation") {
      const queryKey = ["patient reports", data[0].patientId];
      const currentData = queryClient.getQueryData(queryKey);
      if (currentData) {
        const dataMap = _.keyBy(currentData.data, "reportTextId");

        _.forEach(data, (t) => {
          dataMap[t.reportTextId] = t;
        });
        const updatedData = _.values(dataMap);
        queryClient.setQueryData(queryKey, { data: updatedData });
      }
    } else if (entityType === "urgentResultTicket") {
      _.forEach(data, (t) => {
        const queryKey = ["taskWithUrgentResult", t.urgentResults.id];
        // const currentData = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(queryKey, { data: t });
      });
    }
    // (2) update the relevant lists
    updateReportListsWithNewPartialData(data, entityType);
    // (3) update list count
    if (enableListLengthQueries) {
      // the condition here is needed as for react-query ver.3 the refetch bypasses the "enable: false"
      // https://github.com/TanStack/query/issues/1965#issuecomment-1537939434
      queryClient.refetchQueries({ queryKey: [RPT_LIST_LENGTH_FIRST_KEY_VALUE] });
    }
  };

  const onRecMutationError = (error) => {
    if (error?.response?.status === 409) {
      enqueueSnackbar(
        "The item you are trying to save was already updated by another user in the meantime. Please reload the page.",
        {
          variant: "warning",
          persist: true,
          action: ReloadButton,
        }
      );
    } else {
      enqueueSnackbar("Something went wrong.", { variant: "error" });
    }
  };

  const useEditPhysicianFacility = (onSuccessAction) => {
    return useMutation(
      (data) => {
        return axios.post(API + "/physicians/facility", {
          patientId: data.patientId,
          facilityData: data.facilityData,
        });
      },
      {
        onSuccess: (resData, variables) => {
          onSuccessAction(resData, variables);
        },
        onError: (error) => {
          logError(error, { componentStack: "Error on useEditPhysicianFacility" });
        },
      }
    );
  };

  const useConfirmContactInfo = (onSuccessAction) => {
    return useMutation(
      (data) => {
        return axios.post(
          API + "/physicians/confirm-facility-info",
          {
            patientId: data.patientId,
            faxNumber: data.faxNumber,
            facilityId: data.facilityId,
          },
          {
            withCredentials: true,
          }
        );
      },
      {
        onSuccess: (resData, variables) => {
          if (resData.data) {
            onEntityMutationSuccess("recommendation", resData);
          }
          onSuccessAction?.(resData, variables);
        },
        onError: (error) => {
          logError(error, { componentStack: "Error on useConfirmContactInfo" });
        },
      }
    );
  };

  function onSuccessHideReport(resData, { reportTextId }) {
    const queryKey = ["reportLists", "fromUrgentResults"];
    const data = queryClient.getQueryData(queryKey);
    if (data.pages) {
      data.pages.forEach((page) => {
        if (page.data && page.data.tasks) {
          // Filter out tasks with the specified reportTextId
          page.data.tasks = page.data.tasks.filter((task) => task.reportTextId !== reportTextId);
        }
      });
    }
    queryClient.setQueryData(queryKey, data);
  }

  const useHideReport = () => {
    return useMutation(
      (data) => {
        return axios.post(API + "/urgent-results/mark-report-as-having-no-recs", {
          reportTextId: data.reportTextId,
        });
      },
      {
        onSuccess: onSuccessHideReport,
        onError: (error) => {
          logError(error, { componentStack: "Error on useHideReport" });
        },
      }
    );
  };

  const useAddRecommendation = () => {
    return useMutation(
      (newRec) => {
        return axios.post(API + "/recommendations/add-new-recommendations", {
          recommendationData: [newRec],
        });
      },
      {
        onSuccess: (resData) => onEntityMutationSuccess("recommendation", resData),
        onError: (error) => {
          onRecMutationError(error);
          logError(error, { componentStack: "Error on useAddRecommendation" });
        },
      }
    );
  };

  const compareBelongToListIdsAndUpdateCounter = (originalEntity, updatedEntities) => {
    const updatedEntity = updatedEntities.find((item) => item.reportTextId === originalEntity.reportTextId);
    const isMissingId = originalEntity.belongToListIds.some(
      (item) => !updatedEntity.belongToListIds.includes(item) && ["current", "pending"].includes(item)
    );
    if (isMissingId) {
      updateProgressCounter();
    }
  };

  const useUpdateEntityData = (onSuccessAction, entity) => {
    return useMutation(
      ({ entityType, payload }) => {
        if (entityType === "recommendation") {
          return axios.post(
            API + "/recommendations/update",
            {
              payload,
            },
            {
              withCredentials: true,
            }
          );
        } else if (entityType === "urgentResultTicket") {
          return axios.post(
            API + "/urgent-results/update",
            {
              payload,
            },
            {
              withCredentials: true,
            }
          );
        }
      },
      {
        onSuccess: (resData, variables) => {
          onEntityMutationSuccess(variables.entityType, resData);
          if (entity) {
            compareBelongToListIdsAndUpdateCounter(entity, resData.data);
          }
          onSuccessAction?.();
        },
        onError: (error) => {
          onRecMutationError(error);
          logError(error, { componentStack: "Error on useUpdateEntityData" });
        },
      }
    );
  };

  const getMutationOptions = (onSuccessAction, onErrorAction, reports, componentStack) => ({
    onSuccess: (resData) => {
      onSuccessAction?.();
      onEntityMutationSuccess("recommendation", resData);
      reports.forEach((original) => {
        compareBelongToListIdsAndUpdateCounter(original, resData.data);
      });
    },
    onError: (error) => {
      onErrorAction?.();
      onRecMutationError(error);
      logError(error, { componentStack });
    },
  });

  const useSendFax = (onSuccessAction, onErrorAction, reports) => {
    return useMutation(
      (data) => {
        return axios.post(API + "/fax/send", data, {
          withCredentials: true,
        });
      },
      getMutationOptions(onSuccessAction, onErrorAction, reports, "Error on useSendFax")
    );
  };

  const useSendEmail = (onSuccessAction, onErrorAction, reports) => {
    return useMutation(
      (data) => {
        return axios.post(API + "/email/send", data, {
          withCredentials: true,
        });
      },
      getMutationOptions(onSuccessAction, onErrorAction, reports, "Error on useSendEmail")
    );
  };

  const useSendHl7 = (onSuccessAction, onErrorAction, reports) => {
    return useMutation(
      (data) => {
        return axios.post(API + "/hl7/send", data, {
          withCredentials: true,
        });
      },
      getMutationOptions(onSuccessAction, onErrorAction, reports, "Error on useSendHl7")
    );
  };
  const releaseMyTasks = async () => {
    try {
      await axios({
        method: "DELETE",
        url: API + "/tasks/release",
        withCredentials: true,
      });
    } catch (err) {
      throw err;
    }
  };

  const onRpEditError = () => {
    enqueueSnackbar("Error saving referring physician details", { variant: "error" });
  };

  const useEditPhysicianAttributes = () => {
    return useMutation(
      (data) => {
        return axios.post(
          API + "/physicians/attributes",
          { patientId: data.patientId, physicianData: data.physicianData },
          {
            withCredentials: true,
          }
        );
      },
      {
        onSuccess: (resData) => {
          onEntityMutationSuccess("recommendation", resData);
          enqueueSnackbar("Referring physician details saved successfully", { variant: "success" });
        },
        onError: (error) => {
          onRpEditError(error);
          logError(error, { componentStack: "Error on useEditReferringPhysician" });
        },
      }
    );
  };

  return (
    <ApiContext.Provider
      value={{
        getReportListData,
        getReportListDataLength,
        useUpdateEntityData,
        useAddRecommendation,
        useSendFax,
        useSendEmail,
        useSendHl7,
        releaseMyTasks,
        useEditPhysicianAttributes,
        useEditPhysicianFacility,
        useHideReport,
        setEnableListLengthQueries,
        useConfirmContactInfo,
        reportSectionsEndPoint,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export default ApiProvider;
