import React, { useEffect, useState } from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DownloadIcon from "@mui/icons-material/Download";
import ReplayIcon from "@mui/icons-material/Replay";
import Collapse from "@mui/material/Collapse";
import { Card, Skeleton } from "@mui/material";
import { Document, Page } from "react-pdf";

export const PreviewFax = (props) => {
  const {
    recommendationIds,
    setErrorMessage,
    compiledPdf,
    isFetchingCompiledPdf,
    isErrorCompiledPdf,
    refetchCompiledPdf,
    editableFields,
    editMode,
    templateName,
    physicianRole,
  } = props;
  const [faxPageNumber, setFaxPageNumber] = React.useState(1);
  const [numFaxPages, setNumFaxPages] = useState(null);
  const [toggleExpand, setToggleExpand] = useState();

  useEffect(() => {
    if (isErrorCompiledPdf) {
      setErrorMessage("Couldn't generate text.");
    } else {
      setErrorMessage(undefined);
    }
  }, [isErrorCompiledPdf]);

  async function handleOnClickDownloadPdf(e) {
    const response = await axios.post(
      API + "/documents/compile-communication-letter-pdf-from-recommendation-ids-download-link",
      {
        recommendationIds,
        editableFields,
        templateName,
        physicianRole,
      },
      {
        responseType: "arraybuffer",
        withCredentials: true,
      }
    );
    const regex = new RegExp('.*filename="(.*)"');
    const [, filename] = regex.exec(response.headers["content-disposition"]);
    fileDownload(response.data, filename);
  }

  function handleOnClickToggleExpand(e) {
    setToggleExpand(!toggleExpand);
  }

  const scale = toggleExpand ? 3 : 2.7;
  const previewSx = {
    width: `calc(${scale} * 210px)`,
    height: `calc(${scale} * 297px)`,
    transition: "width 0.5s, height 0.5s",
    borderRadius: 1,
  };

  return (
    <Stack direction="row" spacing={1}>
      <Stack id="fax-actions" spacing={1}>
        <Tooltip arrow title="Previous page" placement="left">
          <Box>
            <IconButton
              onClick={() => setFaxPageNumber(faxPageNumber - 1)}
              disabled={!compiledPdf || faxPageNumber === 1}
            >
              <ArrowUpwardIcon />
            </IconButton>
          </Box>
        </Tooltip>
        <Tooltip arrow title="Next page" placement="left">
          <Box>
            <IconButton
              onClick={() => setFaxPageNumber(faxPageNumber + 1)}
              disabled={!compiledPdf || faxPageNumber === numFaxPages}
            >
              <ArrowDownwardIcon />
            </IconButton>
          </Box>
        </Tooltip>
        <Tooltip arrow title="Download PDF" placement="left">
          <Box>
            <IconButton disabled={!compiledPdf || editMode} onClick={handleOnClickDownloadPdf}>
              <DownloadIcon />
            </IconButton>
          </Box>
        </Tooltip>
        <Tooltip arrow title="Regenerate" placement="left">
          <Box>
            <IconButton onClick={refetchCompiledPdf} disabled={isFetchingCompiledPdf || !compiledPdf}>
              <ReplayIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Stack>
      <Collapse orientation="horizontal" in={!isErrorCompiledPdf}>
        <Card id="fax-preview" sx={previewSx} variant="outlined">
          {isFetchingCompiledPdf && <Skeleton variant="rectangular" animation="wave" sx={previewSx} />}
          {!isFetchingCompiledPdf && compiledPdf && (
            <Document
              file={"data:application/pdf;base64, " + compiledPdf}
              loading={<></>}
              onLoadSuccess={({ numPages }) => {
                setNumFaxPages(numPages);
              }}
            >
              <Page pageNumber={faxPageNumber} width="210" scale={scale} loading={<></>} />
            </Document>
          )}
        </Card>
      </Collapse>
    </Stack>
  );
};
