import { Box, Button, Collapse, Paper, Stack, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";

import Alert from "@mui/material/Alert";
import { InputLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";

const Recovery = (props) => {
  useEffect(() => {
    var config = {
      method: "get",
      url: "http://localhost:4434/identities?per_page=100&page=0",
      headers: {},
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return <Box>IDENTITIES</Box>;
};

export default Recovery;
