import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";

const LogoutErrorDialog = (props) => {
  const { open } = props;

  return (
    <Dialog open={open}>
      <DialogTitle>Log out error</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            Logout Error: Your request couldn't be processed due to a system error. Please reload the page.{" "}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => window.location.reload()} variant="contained">
          Reload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutErrorDialog;
