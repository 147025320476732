import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const Logo = (props) => (
  <SvgIcon {...props}>
    <path d="m 3.3519438,15.418364 c 1.8512278,0 3.351944,1.50066 3.351944,3.351818 0,1.851158 -1.5007162,3.351818 -3.351944,3.351818 C 1.5007161,22.122 -1.69888e-7,20.62134 -1.69888e-7,18.770182 -1.69888e-7,16.919024 1.5007161,15.418364 3.3519438,15.418364 Z M 11.9808,-3.608068e-7 c 1.851227,0 3.351944,1.5006599608068 3.351944,3.3518182608068 0,1.8511583 -1.500717,3.3518182 -3.351944,3.3518182 -1.851228,0 -3.3519444,-1.5006599 -3.3519444,-3.3518182 C 8.6288556,1.5006596 10.129572,-3.608068e-7 11.9808,-3.608068e-7 Z M 20.647975,15.418364 c 1.851228,0 3.351944,1.50066 3.351944,3.351818 0,1.851158 -1.500716,3.351818 -3.351944,3.351818 -1.851228,0 -3.351944,-1.50066 -3.351944,-3.351818 0,-1.851158 1.500716,-3.351818 3.351944,-3.351818 z M 16.640002,8.0443634 c 1.851228,0 3.351944,1.50066 3.351944,3.3518186 0,1.851158 -1.500716,3.351818 -3.351944,3.351818 -0.913556,0 -1.741753,-0.365456 -2.346361,-0.95814 -0.955202,-0.701967 -1.737323,-1.052951 -2.346361,-1.052951 -0.609037,0 -1.391158,0.350984 -2.3463606,1.052951 -0.6046076,0.592684 -1.4328046,0.95814 -2.3463608,0.95814 -1.8512277,0 -3.351944,-1.50066 -3.351944,-3.351818 0,-1.8511586 1.5007163,-3.3518186 3.351944,-3.3518186 0.9135562,0 1.7417532,0.3654555 2.3463608,0.9581401 0.9552026,0.7019673 1.7373236,1.0529505 2.3463606,1.0529505 0.609038,0 1.391159,-0.3509832 2.346361,-1.0529505 0.604608,-0.5926846 1.432805,-0.9581401 2.346361,-0.9581401 z" />
  </SvgIcon>
);

export default Logo;
