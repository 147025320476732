import React, { forwardRef } from "react";
import { Box, Typography, Card, Stack, Collapse, CircularProgress, TextField } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { LoadingButton } from "@mui/lab";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Alert from "@mui/material/Alert";
import Logo from "../icons/Logo";
import AgamonLogoText from "../icons/AgamonLogoText";
import "swiper/css";
import Turnstile from "react-turnstile";

const PhysicianLink = ({
  linkData,
  errorMessage,
  infoMessage,
  otp,
  disabledDueToError,
  setOtp,
  sendOtp,
  resendLink,
  submitOtp,
  otpFieldError,
  setSwiperInstance,
  showCaptcha,
  TurnstileWidget,
  handleResendCodeButtonClick,
}) => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card variant="outlined" sx={{ p: 4, width: "500px" }}>
        <Stack spacing={2}>
          <Stack sx={{ pt: 5, alignItems: "center" }} spacing={3}>
            <Stack sx={{ alignItems: "center" }} spacing={1}>
              <Logo
                sx={{
                  fontSize: "2rem",
                  color: "primary.main",
                }}
              />
              <AgamonLogoText sx={{ width: "5rem", color: "primary.main" }} />
            </Stack>
          </Stack>
          <Collapse in={!!errorMessage} sx={{ width: "100%" }}>
            <Alert sx={{ width: "100%" }} severity="error" children={errorMessage} />
          </Collapse>
          <Collapse in={!!infoMessage} sx={{ width: "100%" }}>
            <Alert sx={{ width: "100%" }} severity="info" children={infoMessage} />
          </Collapse>
          {!linkData && (
            <Stack sx={{ alignItems: "center", pb: 5 }}>{!errorMessage && <CircularProgress thickness={5} />}</Stack>
          )}

          {linkData?.isExpired === true && (
            <Box sx={{ textAlign: "center" }}>
              <Stack sx={{ alignItems: "center" }} spacing={2}>
                <Typography>
                  Oops, it looks like this link has expired. Don’t worry! Click the button below to send a new one to
                  your email address.
                </Typography>
                <Stack sx={{ width: "50%" }} spacing={1}>
                  <LoadingButton disabled={disabledDueToError} variant="contained" color="primary" onClick={resendLink}>
                    Resend link
                  </LoadingButton>
                </Stack>
              </Stack>
            </Box>
          )}

          {linkData?.isExpired === false && (
            <Swiper
              autoHeight
              onSlideChange={(swiperInstance) => swiperInstance.updateAutoHeight(swiperInstance.speed)}
              onSwiper={setSwiperInstance}
              allowTouchMove={false}
              slidesPerView={1}
              pagination={{ clickable: true }}
            >
              <SwiperSlide>
                <form onSubmit={sendOtp}>
                  <Box sx={{ textAlign: "center" }}>
                    <Stack sx={{ alignItems: "center" }} spacing={2}>
                      <Typography>
                        To access the platform, we’ll send a one-time code to your email <em>{linkData.email}</em>.
                      </Typography>
                      <Stack sx={{ width: "50%" }} spacing={1}>
                        <LoadingButton
                          disabled={disabledDueToError}
                          variant="contained"
                          color="primary"
                          onClick={sendOtp}
                          fullWidth
                        >
                          Send code
                        </LoadingButton>
                      </Stack>
                    </Stack>
                  </Box>
                </form>
              </SwiperSlide>

              <SwiperSlide>
                <Box sx={{ textAlign: "center" }}>
                  <form onSubmit={submitOtp}>
                    <Stack sx={{ alignItems: "center" }} spacing={2}>
                      <Typography>
                        Please enter the one-time code we've sent to your email <em>{linkData.email}</em>.
                      </Typography>
                      <TextField
                        label="One-time code"
                        type={"tel"}
                        variant="outlined"
                        inputMode={"numeric"}
                        value={otp}
                        aria-label="One-time password"
                        error={!!otpFieldError}
                        helperText={otpFieldError?.length > 0 ? otpFieldError[0].text : " "}
                        onChange={(e) => setOtp(e.target.value)}
                        sx={{ marginBottom: 2 }}
                        fullWidth
                      />
                      <Stack sx={{ width: "50%" }} spacing={1}>
                        <LoadingButton
                          disabled={disabledDueToError}
                          variant="contained"
                          color="primary"
                          onClick={submitOtp}
                        >
                          Submit
                        </LoadingButton>
                      </Stack>
                    </Stack>
                  </form>
                  <Stack sx={{ alignItems: "center" }} spacing={2}>
                    <Stack sx={{ width: "50%" }}>
                      <LoadingButton
                        disabled={disabledDueToError}
                        loading={showCaptcha}
                        variant="text"
                        color="secondary"
                        onClick={handleResendCodeButtonClick}
                      >
                        Resend code
                      </LoadingButton>
                    </Stack>
                    <Box sx={{ height: "65px" }}>{showCaptcha && <>{TurnstileWidget}</>}</Box>
                  </Stack>
                </Box>
              </SwiperSlide>

              <SwiperSlide>
                <Box sx={{ textAlign: "center" }}>
                  <Stack spacing={3} alignItems="center">
                    <CheckCircleOutlineIcon
                      sx={{ fontSize: 70, color: "success.main", animation: "bounce 1.5s infinite" }}
                    />
                    <Typography variant="h4" sx={{ color: "primary.main", fontWeight: "bold" }}>
                      You're in!
                    </Typography>
                    <Typography variant="body1">Hang tight, we're almost there...</Typography>
                    <CircularProgress thickness={5} size={30} sx={{ mt: 2 }} />
                  </Stack>
                </Box>
              </SwiperSlide>
            </Swiper>
          )}
        </Stack>
      </Card>
    </Box>
  );
};

export default PhysicianLink;
