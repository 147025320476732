import * as React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const NoResults = (props) => (
  <SvgIcon {...props}>
    <defs>
      <linearGradient x1="50%" y1="15.6991358%" x2="50%" y2="100%" id="linearGradient-1">
        <stop stopColor="#32A9A1" offset="0%"></stop>
        <stop stopColor="#0E4367" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="No-results" stroke="none" fill="none" fillRule="evenodd">
      <path
        d="M13,5 L13,8 C13,8.55228475 13.4477153,9 14,9 L17,9 C18.1045695,9 19,9.8954305 19,11 L19,19 C19,20.1045695 18.1045695,21 17,21 L7,21 C5.8954305,21 5,20.1045695 5,19 L5,5 C5,3.8954305 5.8954305,3 7,3 L11,3 C12.1045695,3 13,3.8954305 13,5 Z M14.8535534,3.85355339 L18.1464466,7.14644661 C18.3417088,7.34170876 18.3417088,7.65829124 18.1464466,7.85355339 C18.0526784,7.94732158 17.9255015,8 17.7928932,8 L14.5,8 C14.2238576,8 14,7.77614237 14,7.5 L14,4.20710678 C14,3.93096441 14.2238576,3.70710678 14.5,3.70710678 C14.6326082,3.70710678 14.7597852,3.7597852 14.8535534,3.85355339 Z"
        id="Shape"
        fillOpacity="0.3"
        fill="#0E4367"
        fillRule="nonzero"
      ></path>
      <path
        d="M18.9688139,18.9503905 C19.2968234,18.5861031 19.8478793,18.572611 20.2021295,18.9099141 L20.2021295,18.9099141 L21.7240936,20.3805558 C22.0783438,20.7178589 22.0914642,21.2845282 21.7634547,21.6488155 L21.7634547,21.6488155 L21.6978528,21.7162762 C21.3698433,22.0805636 20.8187874,22.0940557 20.4645371,21.7567525 L20.4645371,21.7567525 L18.9425731,20.2861109 C18.5883229,19.9488078 18.5752025,19.3821385 18.903212,19.0178511 L18.903212,19.0178511 Z M15,10 C17.755102,10 20,12.244898 20,15 C20,17.755102 17.755102,20 15,20 C12.244898,20 10,17.755102 10,15 C10,12.244898 12.244898,10 15,10 Z M15,11.3333333 C12.9766573,11.3333333 11.3333333,12.9766573 11.3333333,15 C11.3333333,17.0233427 12.9766573,18.6666667 15,18.6666667 C17.0233427,18.6666667 18.6666667,17.0233427 18.6666667,15 C18.6666667,12.9766573 17.0233427,11.3333333 15,11.3333333 Z"
        id="Path"
        fill="url(#linearGradient-1)"
        fillRule="nonzero"
      ></path>
    </g>
  </SvgIcon>
);

export default NoResults;
