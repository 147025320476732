import React, { useEffect, useState } from "react";
import CoordinateAppConfigContext from "./CoordinateAppConfigContext";
import { useQuery } from "react-query";
import axios from "axios";
import usePermission from "./usePermission";
import _ from "lodash";
import NoResults from "../components/icons/NoResults";
import NoData from "../components/icons/NoData";
import pluralize from "pluralize";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import OutboxIcon from "@mui/icons-material/Outbox";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import AdjustIcon from "@mui/icons-material/Adjust";
import { array, bool, number, object, string } from "yup";

const CoordinateAppConfigProvider = ({ children }) => {
  const { user } = usePermission();

  const {
    isLoading,
    data: fetchedConf,
    isError,
  } = useQuery(
    ["config"],
    () =>
      axios.get(API + "/config", {
        withCredentials: true,
      }),
    {
      select: (data) => {
        return data.data;
      },
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  const reportsTableColumnsToToggle = [
    "reportId",
    "patientName",
    "recommendationsCount",
    "relations",
    "appointments",
    "snooze",
    "modalities",
    "hasAppointments",
    "hasOrRelatedRecs",
    "latestStatusChange",
  ];

  const [conf, setConf] = useState();
  //Taken from configuration file
  const [defaultListRoute, setDefaultListRoute] = useState();
  const [reportsTableHiddenColumns, setReportsTableHiddenColumns] = useState(reportsTableColumnsToToggle);
  const [defaultManageRoute, setDefaultManageRoute] = useState();
  const [defaultCoordinateRoute, setDefaultCoordinateRoute] = useState();
  const [batchCommunication, setBatchCommunication] = useState();
  const [haveAssignees, setHaveAssignees] = useState();
  const [reportLists, setReportLists] = useState();
  const [statuses, setStatuses] = useState();
  const [customerName, setCustomerName] = useState();
  const [includeVerificationScore, setIncludeVerificationScore] = useState();
  const [disableTableSort, setDisableTableSort] = useState();
  const [disableTableFilters, setDisableTableFilters] = useState();
  const [manageListIds, setManageListIds] = useState();
  const [statusesAssignableByUser, setStatusesAssignableByUser] = useState();
  const [appRoot, setAppRoot] = useState();
  const [defaultTimeFrameCategories, setDefaultTimeFrameCategories] = useState();
  const [features, setFeatures] = useState();
  const [editablePhysicianAttributes, setEditablePhysicianAttributes] = useState();
  const [recommendationClosureReasons, setRecommendationClosureReasons] = useState();
  const [availableFindings, setAvailableFindings] = useState();
  const [findingViewerCategoriesAndViews, setFindingViewerCategoriesAndViews] = useState();
  // if appname === "annotator", no config required. Hence default to true. Login app might have same issue. To check.
  // TODO: do config schema def per app
  const [isConfSet, setIsConfSet] = useState(APPNAME === "annotator");
  const [personalize, setPersonalize] = useState({
    "urgent-result": "RAD generated result",
  });

  // Routing
  const showPhysiciansView = true;
  const showReportLists = true;

  useEffect(() => {
    if (availableFindings) {
      const basicDef = [
        {
          key: "pulmonaryNodules",
          urlDynamicParam: "pulmonary-nodules",
          views: [
            { urlDynamicParam: "all", key: "all", label: "All", default: false },
            {
              urlDynamicParam: "with",
              key: "with",
              label: "Reports with pulmonary nodules",
              default: true,
            },
            {
              urlDynamicParam: "without",
              key: "without",
              label: "Reports w/o pulmonary nodules",
              default: false,
            },
          ],
          default: true,
        },
        {
          key: "thyroidNodules",
          urlDynamicParam: "thyroid-nodules",
          views: [
            { urlDynamicParam: "all", key: "all", label: "All", default: false },
            {
              urlDynamicParam: "with",
              key: "with",
              label: "Reports with thyroid nodules",
              default: true,
            },
            {
              urlDynamicParam: "without",
              key: "without",
              label: "Reports w/o thyroid nodules",
              default: false,
            },
          ],
        },
        {
          key: "liverMasses",
          urlDynamicParam: "liver-masses",
        },
        {
          key: "abdominalAorticAneurysm",
          urlDynamicParam: "abdominal-aortic-aneurysm",
        },
        {
          key: "thoracicAorticAneurysm",
          urlDynamicParam: "thoracic-aortic-aneurysm",
        },
      ];
      setFindingViewerCategoriesAndViews(
        _.map(basicDef, (d) => ({ ...d, available: _.includes(availableFindings, d.key) }))
      );
    }
  }, [availableFindings]);

  const defaultRecommendationClosureReasons = [
    {
      value: "Patient arrived to the exam",
      label: "Patient arrived to the exam",
      dateProperties: { dateLabel: "Arrived on" },
    },
    {
      value: "Referring physician declined this recommendation",
      label: "Referring physician declined this recommendation",
    },
    {
      value: "Patient had imaging done elsewhere",
      label: "Patient had imaging done elsewhere",
    },
    {
      value: "Physician failed to respond",
      label: "Physician failed to respond",
    },
    {
      value: "Expired",
      label: "Expired",
    },
  ];

  // TODO: refactor to make it more elegant, add all config?
  useEffect(() => {
    if (
      !_.isNil(defaultListRoute) &
      !_.isNil(defaultManageRoute) &
      !_.isNil(defaultCoordinateRoute) &
      !_.isNil(batchCommunication) &
      !_.isNil(reportLists)
    ) {
      setIsConfSet(true);
    }
  }, [defaultListRoute, defaultManageRoute, defaultCoordinateRoute, batchCommunication, haveAssignees, reportLists]);

  useEffect(() => {
    if (fetchedConf?.config) {
      setConf(fetchedConf.config);
    }
  }, [fetchedConf]);

  useEffect(() => {
    if (manageListIds) {
      setReportLists(
        _.pickBy(
          {
            search: {
              label: "Search",
              hiddenFromMenu: true,
              type: "report",
              requireSearchParams: true,
              emptyState: {
                title: "No results",
                subtitle: "Try searching for something else.",
                Graphics: NoResults,
              },
              url: "search",
            },
            pending: {
              label: "Pending for review",
              type: "report",
              emptyState: {
                title: "Nothing here.",
                subtitle: "Any task that required manual review will appear here.",
                Graphics: NoData,
              },
              url: "pending",
              fetchListLength: true,
            },
            current: {
              label: "Today's tasks",
              type: "report",
              default: true,
              emptyState: {
                title: "Nothing here.",
                subtitle: "Tasks that require immediate attention will appear in this list.",
                Graphics: NoData,
              },
              url: "current",
              fetchListLength: true,
            },
            upcoming: {
              label: "Upcoming tasks",
              type: "report",
              emptyState: {
                title: "Nothing here.",
                subtitle: "Upcoming tasks will appear in this list",
                Graphics: NoData,
              },
              url: "upcoming",
            },
            pendingClosure: {
              label: "Pending closure",
              type: "report",
              emptyState: {
                title: "Nothing here.",
                subtitle: "Tasks that should be closed will appear in this list",
                Graphics: NoData,
              },
              fetchListLength: true,
              url: "pending-closure",
            },
            erFollowUps: {
              label: "ER follow-ups",
              type: "report",
              emptyState: {
                title: "Nothing here.",
                subtitle: "Reports will appear here when patient arrived from ER.",
                Graphics: NoData,
              },
              fetchListLength: true,
              url: "er-follow-ups",
            },
            recentlyModified: {
              label: "Recently modified",
              type: "report",
              emptyState: {
                title: "Nothing here.",
                subtitle: "Any task with a status change will appear here.",
                Graphics: NoData,
              },
              url: "recently-modified",
            },
            closed: {
              label: "Closed",
              type: "report",
              emptyState: {
                title: "Nothing here.",
                subtitle: "Reports will appear here when all their follow-up recommendations are closed.",
                Graphics: NoData,
              },
              url: "closed",
            },
            aiNurseNavigator: {
              label: "AI nurse navigator",
              type: "report",
              emptyState: {
                title: "Nothing here.",
                subtitle: "Tasks that was changed by our AI Nurse Navigator will appear in this list",
                Graphics: NoData,
              },
              fetchListLength: true,
              url: "ai-nurse-navigator",
            },
            urgentResults: {
              label: "Communications",
              type: "urgentResultTicket",
              category: _.capitalize(pluralize(personalize["urgent-result"])),
              emptyState: {
                title: "Nothing here.",
                subtitle: `Open communication tickets will appear here.`,
                Graphics: NoData,
              },
              url: _.kebabCase(pluralize(personalize["urgent-result"])),
            },
            closedUrgentResults: {
              label: "Closed",
              type: "urgentResultTicket",
              category: _.capitalize(pluralize(personalize["urgent-result"])),
              emptyState: {
                title: "Nothing here.",
                subtitle: `Closed communication tickets will appear here.`,
                Graphics: NoData,
              },
              url: `closed-${_.kebabCase(pluralize(personalize["urgent-result"]))}`,
            },
            fromUrgentResults: {
              label: "Follow-ups",
              type: "report",
              category: _.capitalize(pluralize(personalize["urgent-result"])),
              emptyState: {
                title: "Nothing here.",
                subtitle: `Reports will appear here if they contain ${pluralize(personalize["urgent-result"])}.`,
                Graphics: NoData,
              },
              url: `from-${_.kebabCase(pluralize(personalize["urgent-result"]))}`,
            },
          },
          (value, key) => {
            if (manageListIds.includes(key)) {
              return true;
            } else if (value.type === "urgentResultTicket" && features.urgentResults === true) {
              return true;
            } else {
              return false;
            }
          }
        )
      );
    }
  }, [manageListIds, features]);

  useEffect(() => {
    const statuses = [
      {
        name: null,
        logo: NewReleasesOutlinedIcon,
        backgroundColor: "rgba(134,161,179,0.12)",
        rank: 1,
        requiredPermission: "verifyRecommendations",
        category: null,
        assignableByUser: false,
      },
      {
        name: "verified",
        logo: VerifiedOutlinedIcon,
        backgroundColor: "green.main",
        rank: 2,
        requiredPermission: "verifyRecommendations",
        category: "verification",
        assignableByUser: true,
      },
      {
        name: "discarded",
        logo: ThumbDownOutlinedIcon,
        backgroundColor: "red.main",
        rank: 9,
        requiredPermission: "verifyRecommendations",
        category: "verification",
        assignableByUser: true,
      },
      {
        name: "awaiting communication",
        logo: OutboxIcon,
        backgroundColor: "blue.main",
        rank: 3,
        rolesAllowed: "manageRecommendations",
        category: "management",
        assignableByUser: false,
      },
      {
        name: "communication in progress",
        logo: OutboxIcon,
        backgroundColor: "blue.main",
        rank: 3,
        rolesAllowed: "manageRecommendations",
        category: "management",
        assignableByUser: false,
      },
      {
        name: "communication failed",
        logo: PendingActionsIcon,
        backgroundColor: "red.main",
        rank: 3,
        rolesAllowed: "manageRecommendations",
        category: "management",
        assignableByUser: false,
      },
      {
        name: "communicated",
        logo: ForumOutlinedIcon,
        backgroundColor: "blue.main",
        rank: 3,
        rolesAllowed: "manageRecommendations",
        category: "management",
        assignableByUser: true,
      },
      {
        name: "physician replied",
        logo: ForumOutlinedIcon,
        backgroundColor: "blue.main",
        rank: 3,
        rolesAllowed: "manageRecommendations",
        category: "management",
        assignableByUser: true,
      },
      {
        name: "ordered",
        logo: AssignmentOutlinedIcon,
        backgroundColor: "purple.main",
        rank: 4,
        rolesAllowed: "manageRecommendations",
        category: "management",
        assignableByUser: true,
      },
      {
        name: "replied not ordered",
        logo: AssignmentLateOutlinedIcon,
        backgroundColor: "purple.main",
        rank: 5,
        rolesAllowed: "manageRecommendations",
        category: "management",
        assignableByUser: true,
      },
      {
        name: "scheduled",
        logo: PendingActionsIcon,
        backgroundColor: "purple.main",
        rank: 5,
        rolesAllowed: "manageRecommendations",
        category: "management",
        assignableByUser: true,
      },
      {
        name: "no show",
        logo: EventBusyIcon,
        backgroundColor: "yellow.main",
        rank: 6,
        rolesAllowed: "manageRecommendations",
        category: "management",
        assignableByUser: true,
      },
      {
        name: "canceled",
        logo: EventBusyIcon,
        backgroundColor: "yellow.main",
        rank: 7,
        rolesAllowed: "manageRecommendations",
        category: "management",
        assignableByUser: true,
      },
      {
        name: "closed",
        logo: TaskAltOutlinedIcon,
        backgroundColor: "gray.main",
        rank: 9,
        rolesAllowed: "manageRecommendations",
        category: "management",
        assignableByUser: true,
      },
      {
        name: "open",
        logo: AdjustIcon,
        backgroundColor: "green.main",
        rank: 1,
        category: "urgentResults",
        assignableByUser: true,
      },
    ];
    if (statusesAssignableByUser) {
      statuses.forEach((element) => {
        if (!_.includes(statusesAssignableByUser, element.name)) {
          element.assignableByUser = false;
        }
      });
    }
    setStatuses(statuses);
  }, [statusesAssignableByUser]);

  useEffect(() => {
    if (conf) {
      let configSchema = object({
        defaultListRoute: string().required(),
        customerName: string().required(),
        defaultManageRoute: string().required(),
        defaultCoordinateRoute: string().required(),
        batchCommunication: bool().required(),
        manageListIds: array().of(string()).required(),
        statusesAssignableByUser: array().of(string()),
        appRoot: string().required(),
        defaultTimeFrameCategories: object({
          tfType: string().required(),
          unit: string().required(),
          count: number().required(),
        }).required(),
        findings: array().of(string()).optional(),
      });
      configSchema
        .validate(conf)
        .then(() => {
          setDefaultListRoute(conf["defaultListRoute"]);
          setCustomerName(conf["customerName"]);
          setDefaultManageRoute(conf["defaultManageRoute"]);
          setDefaultCoordinateRoute(conf["defaultCoordinateRoute"]);
          setBatchCommunication(conf["batchCommunication"]);
          setHaveAssignees(conf["haveAssignees"]);
          setIncludeVerificationScore(conf["includeVerificationScore"]);
          setManageListIds(conf["manageListIds"]);
          setStatusesAssignableByUser(conf["statusesAssignableByUser"]);
          setAppRoot(conf["appRoot"]);
          setDefaultTimeFrameCategories(conf["defaultTimeFrameCategories"]);
          setDisableTableSort(conf["disableTableSort"]);
          setDisableTableFilters(conf["disableTableFilters"]);
          setFeatures(conf["features"]);
          setRecommendationClosureReasons(conf["recommendationClosureReasons"] || defaultRecommendationClosureReasons);
          setEditablePhysicianAttributes(conf["editablePhysicianAttributes"]);
          setAvailableFindings(conf["availableFindings"]);
        })
        .catch((err) => console.error(`Config validation error`, err));
    }
  }, [conf]);

  const patientClassMapping = { E: "ED", I: "Inpatient", O: "Outpatient" };

  const communicatedToMapping = {
    rp: "RP",
    pcp: "PCP",
    rpandpcp: "RP and PCP",
    externalprovider: "External Provider",
    patient: "Patient",
  };

  const isSettingsMenuItemAvailable = true;

  return (
    <CoordinateAppConfigContext.Provider
      value={{
        isSettingsMenuItemAvailable,
        isConfSet,
        statuses,
        reportLists,
        showReportLists,
        showPhysiciansView,
        findingViewerCategoriesAndViews,
        defaultManageRoute,
        batchCommunication,
        includeVerificationScore,
        haveAssignees,
        defaultCoordinateRoute,
        defaultListRoute,
        customerName,
        appRoot,
        defaultTimeFrameCategories,
        disableTableSort,
        disableTableFilters,
        features,
        recommendationClosureReasons,
        editablePhysicianAttributes,
        patientClassMapping,
        communicatedToMapping,
        personalize,
        reportsTableHiddenColumns,
        reportsTableColumnsToToggle,
        setReportsTableHiddenColumns,
      }}
    >
      {children}
    </CoordinateAppConfigContext.Provider>
  );
};

export default CoordinateAppConfigProvider;
