import * as Yup from "yup";

import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";

import Invite from "./Invite";
import _ from "lodash";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import useCoordinateAppConfig from "../../providers/useCoordinateAppConfig";

/**
 *  FIXME: When endpoint provide support for AJAX, change the "hacky" solution
 *  FIXME: When setting a password, sometimes a 403 was received instead with
 *  the error { id: "session_refresh_required" message: "The requested action
 *  was forbidden" reason: "The login session is too old and thus not allowed to
 *  update these fields. Please re-authenticate." } This shouldn't happen as
 *  long as in Kratos configurations, `privileged_session_max_age` is equal to the
 *  user session lifespan.
 */

const InviteWrapper = (props) => {
  const [errorMessagePassword, setErrorMessagePassword] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [flowId, setFlowId] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null);
  const [recoveryToken, setRecoveryToken] = useState(null);
  const [user, setUser] = useState();
  const [sessionExpired, setSessionExpired] = useState();
  const [recoveryFlow, setRecoveryFlow] = useState();
  const [totpQr, setTotpQr] = useState();
  const [totpUnlink, setTotpUnlink] = useState();
  const [totpSecret, setTotpSecret] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState();

  const formPasswordSchema = Yup.object().shape({
    password: Yup.string().required("Password is mandatory").min(3, "Password must be at 3 char long"),
    confirmPassword: Yup.string()
      .required("Password is mandatory")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });
  const formPasswordOptions = { resolver: yupResolver(formPasswordSchema) };
  const { appRoot } = useCoordinateAppConfig();

  const {
    control: controlProfile,
    handleSubmit: handleSubmitProfile,
    setError: setErrorProfile,
    formState: { errors: errorsProfile },
  } = useForm();
  const {
    control: controlPassword,
    handleSubmit: handleSubmitPassword,
    setError: setErrorPassword,
    formState: { errors: errorsPassword },
  } = useForm(formPasswordOptions);
  const {
    control: controlTotp,
    handleSubmit: handleSubmitTotp,
    setError: setErrorTotp,
    formState: { errors: errorsTotp },
  } = useForm();

  useEffect(() => {}, [errorsProfile, errorsPassword, errorsTotp]);

  function onSubmit(formData, method) {
    var setError;
    var data;
    if (method === "password") {
      data = { password: formData.password };
      setError = setErrorPassword;
    } else if (method === "totp") {
      data = formData;
      setError = setErrorTotp;
    } else if (method === "profile") {
      data = {
        traits: {
          name: {
            first: formData.firstName,
            last: formData.lastName,
          },
          email: user.traits?.email,
        },
      };
      setError = setErrorProfile;
    } else {
      throw "No valid method";
    }

    axios
      .post(
        KRATOS_PUBLIC_API + "/self-service/settings",
        {
          method,
          ...data,
          csrf_token: csrfToken,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            flow: flowId,
          },
        }
      )
      .then((res) => {
        // If not last step, initialize another settings flow.
        if (activeStep !== 2) {
          axios
            .get(KRATOS_PUBLIC_API + "/self-service/settings/browser", {
              withCredentials: true,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              setFlowId(response.data.id);
              setCsrfToken(response.data.ui.nodes[0].attributes.value);
              setTotpQr(
                _.find(response.data.ui.nodes, {
                  attributes: { id: "totp_qr" },
                })
              );
              setTotpUnlink(
                _.find(response.data.ui.nodes, {
                  attributes: { name: "totp_unlink" },
                })
              );
              setTotpSecret(
                _.find(response.data.ui.nodes, {
                  attributes: { text: { id: 1050006 } },
                })
              );
            });
        }
        if (formData.totp_unlink) {
        } else {
          setActiveStep((prev) => prev + 1);
        }
      })
      .catch((error) => {
        try {
          if (error.response) {
            var errorMessages = [];
            if (error.response.data.ui.messages) {
              errorMessages = errorMessages.concat(error.response.data.ui.messages);
            }
            errorMessages = errorMessages.concat(
              _.chain(error.response.data.ui.nodes)
                .map((node) =>
                  node.messages.map((message) => ({
                    ...message,
                    fieldName: node.attributes.name,
                  }))
                )
                .flatten()
                .uniqBy("id")
                .value()
            );
            if (errorMessages.length > 0) {
              if (errorMessages.length === 1) {
                const errorMessage = errorMessages[0];
                if (errorMessage.fieldName) {
                  // TODO: Also show errors not related to a specific field.
                  setError(errorMessage.fieldName, {
                    message: errorMessage.text,
                    type: errorMessage.type,
                  });
                }
              }
            }
          }
        } catch (err) {
          console.log(err);
        }
      });
  }

  useEffect(() => {
    // ATTENTION: The code below makes a "hacky" solution to an issue with
    // Kratos API. When a user invite link is generated, a recovery flow is
    // generated behind the scenes. Sending the following POST request to
    // `/self-service/recovery` ends a recovery flow. Ending a recovery flow
    // does one of the following: Logs the user in if the flow, token and CSRF
    // tokens are vaild and the recovery token has yet to expire; otherwise,
    // initializes a new recovery flow. Unfortunately and surprisingly, this
    // endpoint does this all together with some unwanted redirects, and
    // currently there is no support for AJAX calls. Performing the AXIOS
    // request as below, all the redirects requests are sent by the browser, and
    // AXIOS returns the last response. Whether that's an OK response or an
    // error, it is irrelevant to make sense of it, as it also affected by our
    // server's configurations. However, intermediate responses containing the
    // Set-Cookie header are read by the browser, and so sending this request is
    // the only way to log the user in without credentials, so they can set
    // their own. The subsequent request to `sessions/whoami` will determine the
    // result of the first one. With proper support, it could be discarded. It
    // returns the details of the active session. If the first request
    // succeeded, or if a user is already logged in, we can proceed to
    // initialize a new settings flow (one was already initialized by the
    // successful first request, but we have no way of getting that flow's ID
    // since it was a part of the redirect URL query parameters). Otherwise,
    // show an error message.
    async function userRecoverySignIn() {
      const flow = searchParams.get("flow"); // Recovery flow ID
      const token = searchParams.get("token"); // Recovery token

      if (token && flow) {
        var config = {
          method: "get",
          url: KRATOS_PUBLIC_API + "/self-service/recovery",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
          params: {
            flow,
            token,
          },
        };
        await axios(config).catch((error) => {
          console.error("Error in getting recovery flow; ", error); // Unforturanly we'd like one of the errors to happen, without catching it the app will crash.
        }); // Several redirections, if goes well, ends with an active but unreachable settings flow and a logged in user.

        config = {
          method: "get",
          url: KRATOS_PUBLIC_API + "/sessions/whoami",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
        };

        axios(config)
          .then((response) => {
            setUser(response.data?.identity);
          })
          .catch((error) => {
            console.error("Encountered an error while trying to check for an active session; ", error);
            setUser(null);
          });
      } else {
        setUser(null);
        console.error("Couldn't get token and flow from query parameters.");
      }
    }
    setIsLoading(true);
    userRecoverySignIn();
  }, []);

  useEffect(() => {
    if (user === null) {
      setIsError(true);
      setIsLoading(false);
    } else if (user) {
      setIsLoading(false);
      var config = {
        method: "get",
        url: KRATOS_PUBLIC_API + "/self-service/settings/browser",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        withCredentials: true,
      };

      axios(config)
        .then((response) => {
          setFlowId(response.data.id);
          setCsrfToken(response.data.ui.nodes[0].attributes.value);
          setTotpQr(_.find(response.data.ui.nodes, { attributes: { id: "totp_qr" } }));
        })
        .catch((error) => {});
    }
  }, [user]);

  useEffect(() => {
    if (activeStep === 3) {
      window.location.replace(appRoot);
    }
  }, [activeStep]);

  return (
    <Invite
      isLoading={isLoading}
      isError={isError}
      controlPassword={controlPassword}
      controlTotp={controlTotp}
      controlProfile={controlProfile}
      handleSubmitProfile={handleSubmitProfile}
      handleSubmitPassword={handleSubmitPassword}
      handleSubmitTotp={handleSubmitTotp}
      onSubmit={onSubmit}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      totpQr={totpQr}
      totpUnlink={totpUnlink}
      totpSecret={totpSecret}
      user={user}
      errorMessagePassword={errorMessagePassword}
    />
  );
};

export default InviteWrapper;
