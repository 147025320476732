import * as React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const CopyIcon = (props) => (
  <SvgIcon {...props}>
    <path d="m7 7v10c0 1.0543618.81587779 1.9181651 1.85073766 1.9945143l.14926234.0054857h6c0 1.1045695-.8954305 2-2 2h-6c-1.1045695 0-2-.8954305-2-2v-10c0-1.1045695.8954305-2 2-2zm10-4c1.1045695 0 2 .8954305 2 2v10c0 1.1045695-.8954305 2-2 2h-6c-1.1045695 0-2-.8954305-2-2v-10c0-1.1045695.8954305-2 2-2z" />
  </SvgIcon>
);

export default CopyIcon;
