import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { logError } from "../utils/logError";

// for more explanation on this component, see: https://reactjs.org/docs/error-boundaries.html
// It is currently written as class component rather than as function component as React did't release yet the relevant
// hooks.

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (this.props.user) {
      logError(error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container
          sx={{
            display: "flex",
            height: "100vh",
            width: "100vw",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack spacing={2} direction="column" sx={{ alignItems: "center" }}>
            <Typography variant="h4">Oops! An unexpected error occurred.</Typography>
            <Typography variant="subtitle1">
              We're working hard to fix this. Please contact us if this problem persists.
            </Typography>
            <Box>
              <Button sx={{ mr: 2 }} component={Link} size="large" variant="contained" to="/">
                Back home
              </Button>
              <Button
                component="a"
                variant="outlined"
                href="mailto:support@agamonhealth.com"
                target="_blank"
                size="large"
              >
                Contact us
              </Button>
            </Box>
          </Stack>
        </Container>
      );
    }

    return this.props.children;
  }
}

module.exports = {
  ErrorBoundary,
};
