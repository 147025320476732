import { Box, Button, Card, Collapse, Container, Paper, Stack, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";

import AgamonLogoText from "../icons/AgamonLogoText";
import Alert from "@mui/material/Alert";
import EmptyState from "../EmptyState";
import { InputLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LoadingScreen from "../LoadingScreen";
import Logo from "../icons/Logo";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import _ from "lodash";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

var qs = require("qs");

const Invite = (props) => {
  const {
    isLoading,
    isError,
    controlPassword,
    controlTotp,
    controlProfile,
    handleSubmitProfile,
    handleSubmitTotp,
    handleSubmitPassword,
    activeStep,
    onSubmit,
    totpQr,
    totpUnlink,
    totpSecret,
    user,
    setActiveStep,
    errorMessagePassword,
  } = props;

  if (isLoading) {
    return <LoadingScreen />;
  }
  if (isError) {
    return (
      <Container
        sx={{
          bgcolor: "background.default",
          display: "flex",
          height: "100vh",
          width: "100vw",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack direction="column" sx={{ alignItems: "center" }}>
          <EmptyState
            title="Invalid invitation link"
            subtitle="This link is either invalid or expired. Please contact our team."
          />
          <Box sx={{ mt: 2 }}>
            <Button component="a" variant="contained" href="mailto:support@agamonhealth.com" target="_blank">
              Contact us
            </Button>
          </Box>
        </Stack>
      </Container>
    );
  }

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "scroll",
      }}
    >
      <Card variant="outlined" sx={{ overflow: "unset", p: 4, width: "500px", mt: 3, mb: 3 }}>
        <Stack sx={{ marginY: 4, alignItems: "center" }} spacing={3}>
          <Stack sx={{ alignItems: "center" }} spacing={1}>
            <Logo
              sx={{
                fontSize: "2rem",
                color: "primary.main",
              }}
            />
            <AgamonLogoText sx={{ width: "5rem", color: "primary.main" }} />
          </Stack>
          <Typography>
            Hi, <em>{user?.traits?.email}</em>! Please complete the next steps to finish setting up your new account.
          </Typography>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step>
                <StepLabel>Personal information</StepLabel>
                <StepContent TransitionProps={{ unmountOnExit: false }}>
                  <form>
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      <Stack sx={{ width: "100%" }}>
                        <InputLabel shrink>First name</InputLabel>
                        <Controller
                          name="firstName"
                          control={controlProfile}
                          rules={{ required: true }}
                          render={({ field, fieldState }) => (
                            <TextField {...field} placeholder="First name" fullWidth error={!!fieldState.error} />
                          )}
                        />
                      </Stack>
                      <Stack sx={{ width: "100%" }}>
                        <InputLabel shrink>Last name</InputLabel>
                        <Controller
                          name="lastName"
                          control={controlProfile}
                          rules={{ required: true }}
                          render={({ field, fieldState }) => (
                            <TextField {...field} placeholder="Last name" fullWidth error={!!fieldState.error} />
                          )}
                        />
                      </Stack>
                      <Controller
                        name="submit"
                        control={controlProfile}
                        render={({ field, fieldState }) => (
                          <LoadingButton
                            variant="contained"
                            type="submit"
                            loading={fieldState.isSubmitting}
                            fullWidth
                            onClick={handleSubmitProfile((data) => onSubmit(data, "profile"))}
                          >
                            Submit
                          </LoadingButton>
                        )}
                      />
                    </Stack>
                  </form>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Choose a password</StepLabel>
                <StepContent TransitionProps={{ unmountOnExit: false }}>
                  <form>
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      <Collapse in={!!errorMessagePassword} sx={{ width: "100%" }}>
                        <Alert sx={{ width: "100%" }} severity="error">
                          {errorMessagePassword}
                        </Alert>
                      </Collapse>
                      <Stack sx={{ width: "100%" }}>
                        <InputLabel shrink>Password</InputLabel>
                        <Controller
                          name="password"
                          control={controlPassword}
                          rules={{ required: true }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Password"
                              type="password"
                              fullWidth
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Stack>
                      <Stack sx={{ width: "100%" }}>
                        <InputLabel shrink>Confirm password</InputLabel>
                        <Controller
                          name="confirmPassword"
                          control={controlPassword}
                          rules={{ required: true }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              placeholder="Confirm password"
                              type="password"
                              fullWidth
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Stack>
                      <LoadingButton
                        variant="contained"
                        type="submit"
                        onClick={handleSubmitPassword((data) => onSubmit(data, "password"))}
                        fullWidth
                      >
                        Set password
                      </LoadingButton>
                    </Stack>
                  </form>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Set up 2FA</StepLabel>
                <StepContent TransitionProps={{ unmountOnExit: false }}>
                  <form>
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      {totpUnlink && (
                        <>
                          <Typography>TOTP code is set up.</Typography>
                          {/* <LoadingButton
                            variant="contained"
                            type="submit"
                            fullWidth
                            onClick={handleSubmitTotp((data) =>
                              onSubmit({ ...data, totp_unlink: true }, "totp")
                            )}
                          >
                            Unlink TOTP
                          </LoadingButton> */}
                        </>
                      )}
                      {!totpUnlink && (
                        <>
                          <Typography>
                            Add a TOTP Authenticator App to your account to improve your account security. Popular
                            Authenticator apps are LastPass, Duo Mobile and Google Authenticator. Scan the QR code or
                            insert the secret key to the app.
                          </Typography>
                          {totpQr && totpSecret && (
                            <>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={totpQr.attributes.src}
                                  width={totpQr.attributes.width}
                                  height={totpQr.attributes.height}
                                />
                              </Box>

                              <Stack sx={{ width: "100%" }}>
                                <InputLabel shrink>TOTP secret key</InputLabel>
                                <code style={{ padding: 0, margin: 0 }}>{totpSecret.attributes.text.text}</code>
                              </Stack>

                              <Stack sx={{ width: "100%" }}>
                                <InputLabel shrink>6 digit code</InputLabel>
                                <Controller
                                  name="totp_code"
                                  control={controlTotp}
                                  rules={{ required: true }}
                                  render={({ field, fieldState }) => (
                                    <TextField
                                      {...field}
                                      placeholder="6 digit code"
                                      fullWidth
                                      error={!!fieldState.error}
                                      helperText={
                                        !!fieldState.error
                                          ? fieldState.error?.message
                                          : "This is your authenticator app code"
                                      }
                                    />
                                  )}
                                />
                              </Stack>
                            </>
                          )}

                          <LoadingButton
                            variant="contained"
                            type="submit"
                            fullWidth
                            onClick={handleSubmitTotp((data) => onSubmit(data, "totp"))}
                          >
                            Submit code
                          </LoadingButton>
                          <LoadingButton variant="outlined" fullWidth onClick={() => setActiveStep((prev) => prev + 1)}>
                            Set up later
                          </LoadingButton>
                        </>
                      )}
                    </Stack>
                  </form>
                </StepContent>
              </Step>
            </Stepper>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

export default Invite;
