import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as cheerio from "cheerio";
import IconButton from "@mui/material/IconButton";
import CopyIcon from "../../icons/CopyIcon";
import Collapse from "@mui/material/Collapse";
import { Card, Skeleton } from "@mui/material";

export const PreviewText = (props) => {
  const { setErrorMessage, compiledText, isFetchingCompiledText, isErrorCompiledText } = props;

  useEffect(() => {
    if (isErrorCompiledText) {
      setErrorMessage("Couldn't generate text.");
    } else {
      setErrorMessage(undefined);
    }
  }, [isErrorCompiledText]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const scale = 2.7;
  const previewSx = {
    width: `calc(${scale} * 210px)`,
    height: `calc(${scale} * 297px)`,
    borderRadius: 1,
  };
  return (
    <Stack direction="row" spacing={1}>
      <Stack spacing={1}>
        <Tooltip arrow title={"Copy to clipboard"} placement="left">
          <Box>
            <CopyToClipboard
              onCopy={() => enqueueSnackbar("Text copied!")}
              text={cheerio
                .load(compiledText || "")("body")
                .text()}
            >
              <IconButton disabled={!compiledText}>
                <CopyIcon />
              </IconButton>
            </CopyToClipboard>
          </Box>
        </Tooltip>
      </Stack>
      <Collapse orientation="horizontal" in={!isErrorCompiledText}>
        <Card id="text-preview" sx={{ ...previewSx }} variant="outlined">
          {isFetchingCompiledText && <Skeleton variant="rectangular" animation="wave" sx={previewSx} />}
          {!isFetchingCompiledText && compiledText && (
            <Box sx={{ p: 2, whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: compiledText }}></Box>
          )}
        </Card>
      </Collapse>
    </Stack>
  );
};
