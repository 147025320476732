import "swiper/css";

import { Checkbox, FormControlLabel, FormGroup, Stack, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { InputLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

const LdapLoginSlides = (props) => {
  const { swiper, setSwiper, setErrorMessage } = props;
  const [searchParams] = useSearchParams();
  const [loginChallenge, setLoginChallenge] = useState();
  const [isRedirecting, setIsRedirecting] = useState(false);

  const { control: controlOidc, handleSubmit: handleSubmitOidc } = useForm();

  const { control: controlPassword, handleSubmit: handleSubmitPassword } = useForm();

  async function handleErrorResponse(error) {
    if (error.response?.data) {
      const body = error.response.data;
      if (body.error) {
        const error = body.error;
        if (error.id === "self_service_flow_expired") {
        } else {
          if (error.message) {
            setErrorMessage(error.message);
          } else {
            setErrorMessage("An unknown error occured.");
          }
        }
      } else if (body.ui) {
        handleUserFacingErrors(body);
      }
    } else {
      setErrorMessage("An unknown error occured.");
    }
  }

  async function onSubmit(formData, method) {
    setErrorMessage();
    await new Promise((resolve) => {
      // UX: makes user sees error message getting cleaned up between attempts.
      setTimeout(() => {
        resolve();
      }, 1000);
    });
    if (method === "oidc") {
      axios
        .post(
          AUTH_API + "/ldap/login",
          {
            username: formData.username,
            password: formData.password,
            remember: formData.remember,
            login_challenge: loginChallenge,
          },
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.redirect_to) {
            window.location.replace(res.data.redirect_to);
          }
        })
        .catch(handleErrorResponse);
    } else if (method === "password") {
      setIsRedirecting(true);
      window.location.replace("/login"); // FIXME: Loses any query parameters that might have been appended beforehand.
    }
  }

  useEffect(() => {
    const loginChallengeParam = searchParams.get("login_challenge");
    if (loginChallengeParam) setLoginChallenge(loginChallengeParam);
    else setErrorMessage("Something's not right.");
  }, []);

  return (
    <Swiper
      simulateTouch={false}
      touchRatio={0}
      slidesPerView={1}
      enabled={true}
      speed={300} // That's actually the default
      onSwiper={(swiperInstance) => setSwiper(swiperInstance)}
      onSlideChange={(swiperInstance) => swiperInstance.updateAutoHeight(swiperInstance.speed)}
    >
      <SwiperSlide>
        <form onSubmit={handleSubmitOidc((data) => onSubmit(data, "oidc"))}>
          <Stack sx={{ alignItems: "center" }} spacing={2}>
            <Typography sx={{ width: "100%" }}>Sign into Agamon using your LDAP credentials.</Typography>
            <Stack sx={{ width: "100%" }} spacing={1}>
              <InputLabel shrink>Username</InputLabel>
              <Controller
                name="username"
                control={controlOidc}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <TextField {...field} placeholder="Enter username" fullWidth error={!!fieldState.error} />
                )}
              />
            </Stack>
            <Stack sx={{ width: "100%" }} spacing={1}>
              <InputLabel shrink>Password</InputLabel>
              <Controller
                name="password"
                control={controlOidc}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="Enter password"
                    type="password"
                    fullWidth
                    error={!!fieldState.error}
                  />
                )}
              />
            </Stack>

            <Stack sx={{ width: "50%" }} spacing={1}>
              <Controller
                name="submit"
                control={controlOidc}
                render={({ field, formState: { isSubmitting } }) => (
                  <LoadingButton variant="contained" type="submit" size="large" loading={isSubmitting} fullWidth>
                    Sign in with LDAP
                  </LoadingButton>
                )}
              />
              <Controller
                name="submit"
                control={controlPassword}
                render={({ field, formState: { isSubmitting } }) => (
                  <LoadingButton
                    onClick={handleSubmitPassword((data) => onSubmit(data, "password"))}
                    loading={isSubmitting || isRedirecting}
                    fullWidth
                  >
                    Sign in with email
                  </LoadingButton>
                )}
              />
            </Stack>
            <Stack sx={{ width: "100%" }} spacing={1}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Controller
                      defaultValue={false}
                      name="remember"
                      control={controlOidc}
                      render={({ field }) => <Checkbox {...field} checked={field.value} />}
                    />
                  }
                  label="Remember me"
                />
              </FormGroup>
            </Stack>
          </Stack>
        </form>
      </SwiperSlide>
    </Swiper>
  );
};

export default LdapLoginSlides;
