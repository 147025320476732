import React, { useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "./editor-styles.css";

const Editor = ({ value, onChange }) => {
  const containerRef = useRef(null);
  const quillInstance = useRef(null);

  useEffect(() => {
    const toolbarOptions = [
      [{ header: "1" }, { header: "2" }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
      ["clean"],
    ];

    const container = containerRef.current;
    const editorContainer = container.appendChild(container.ownerDocument.createElement("div"));

    quillInstance.current = new Quill(editorContainer, {
      theme: "snow",
      modules: {
        toolbar: toolbarOptions,
      },
    });

    quillInstance.current.clipboard.dangerouslyPasteHTML(value);

    quillInstance.current.on("text-change", () => {
      onChange(quillInstance.current.root.innerHTML);
    });

    return () => {
      container.innerHTML = "";
      quillInstance.current.off("text-change");
      quillInstance.current = null;
    };
  }, [quillInstance]);

  return <div ref={containerRef} />;
};

export default Editor;
