import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

const LoadingScreen = () => (
  <Box
    sx={{
      width: "100%",
      height: "70%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <CircularProgress size={100} thickness={5} sx={{ m: 5 }} />
  </Box>
);

export default LoadingScreen;
