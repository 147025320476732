
const React = require("react");
const { Route, Routes } = require("react-router-dom");
const { default: NotFoundView } = require("../components/NotFoundView");
const { default: SsoApp } = require("../components/sso/App");

const AppRoutes = (props) => (
  <Routes>
    <Route path="*" element={<SsoApp />} key="loginRoute" />
    <Route path="*" element={<NotFoundView isFullScreen={true} />} key="404Route" />
  </Routes>
);

export default AppRoutes;
