import { Controller } from "react-hook-form";
import React from "react";
import { FormControl, MenuItem, NativeSelect, Select, OutlinedInput } from "@mui/material";

const ReactHookFormSelect = ({ name, label, control, defaultValue, children, rules, error, options, ...props }) => {
  return (
    <FormControl {...props} fullWidth>
      <Controller
        render={({ field, fieldState }) => (
          <NativeSelect
            variant="outlined"
            input={<OutlinedInput />}
            onChange={props.onChange}
            label={label}
            {...field}
            error={!!fieldState.error}
          >
            <option key={-1} value={""} disabled>
              {""}
            </option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label || option.value}
              </option>
            ))}
          </NativeSelect>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        error={error}
      />
    </FormControl>
  );
};
export default ReactHookFormSelect;
