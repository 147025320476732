import { Navigate, Route, Routes } from "react-router-dom";

import InviteWrapper from "./InviteWrapper";
import Login from "./Login";
import React from "react";
import Recovery from "./Recovery";
import Register from "./Register";
import PhysicianLinkWrapper from "./PhysicianLinkWrapper";
import NotFoundView from "../NotFoundView";

const App = (props) => {
  return (
    <Routes>
      <Route exact path="/" element={<Navigate to="login" />} />
      <Route exact path="/login/ldap" element={<Login ldap />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/invite" element={<InviteWrapper />} />
      <Route exact path="/recovery" element={<Recovery />} />
      <Route path={"/physicians/links/:id"} element={<PhysicianLinkWrapper />} />
      <Route exact path="/404" element={<NotFoundView isFullScreen={true} />} />
    </Routes>
  );
};

export default App;
