import "swiper/css";

import { Box, Card, Collapse, Stack } from "@mui/material";
import React, { useState } from "react";

import AgamonLogoText from "../icons/AgamonLogoText";
import Alert from "@mui/material/Alert";
import DefaultLoginSlides from "./DefaultLoginSlides";
import LdapLoginSlides from "./LdapLoginSlides";
import Logo from "../icons/Logo";

const Login = (props) => {
  const [errorMessage, setErrorMessage] = useState();
  const [swiper, setSwiper] = useState();

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card variant="outlined" sx={{ p: 4, width: "500px" }}>
        <Stack spacing={2}>
          <Stack sx={{ pt: 5, alignItems: "center" }} spacing={3}>
            <Stack sx={{ alignItems: "center" }} spacing={1}>
              <Logo
                sx={{
                  fontSize: "2rem",
                  color: "primary.main",
                }}
              />
              <AgamonLogoText sx={{ width: "5rem", color: "primary.main" }} />
            </Stack>
          </Stack>

          <Collapse in={!!errorMessage} sx={{ width: "100%" }}>
            <Alert sx={{ width: "100%" }} severity="error">
              {errorMessage}
            </Alert>
          </Collapse>
          {props.ldap ? (
            <LdapLoginSlides setErrorMessage={setErrorMessage} swiper={swiper} setSwiper={setSwiper} />
          ) : (
            <DefaultLoginSlides setErrorMessage={setErrorMessage} swiper={swiper} setSwiper={setSwiper} />
          )}
        </Stack>
      </Card>
    </Box>
  );
};

export default Login;
