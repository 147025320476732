import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Slide,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const SessionTimeoutDialog = (props) => {
  const { open, handleOnClickContinueSession } = props;

  return (
    <Dialog open={open}>
      <DialogTitle>Session timeout</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>The current session is about to expire in a few seconds and you would be logged out.</Typography>
          <Typography>Would you like to continue the session?</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClickContinueSession} variant="contained">
          Continue session
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionTimeoutDialog;
