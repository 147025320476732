import * as React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const NoData = (props) => (
  <SvgIcon {...props}>
    <defs>
      <linearGradient x1="92.1504321%" y1="57.8495679%" x2="7.84956791%" y2="57.8495679%" id="linearGradient-1">
        <stop stopColor="#32A9A1" offset="0%"></stop>
        <stop stopColor="#0E4367" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Task-done" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-21" transform="translate(3.000000, 5.027498)" fillRule="nonzero">
        <g id="Group-20">
          <path
            d="M17.2332611,4.61437047 C17.649334,4.61437047 17.9866277,4.95166419 17.9866277,5.36773708 L17.9866277,8.3812035 C17.9866277,8.79727639 17.649334,9.13457011 17.2332611,9.13457011 L0.753366607,9.13457011 C0.337293719,9.13457011 0,8.79727639 0,8.3812035 L0,5.36773708 C0,4.95166419 0.337293719,4.61437047 0.753366607,4.61437047 L17.2332611,4.61437047 Z M16.7515118,5.82481067 C16.6162544,5.68581186 16.3998566,5.6810188 16.2590033,5.81043148 L16.2442081,5.82481067 L14.9994278,7.10402291 L14.4271394,6.51590434 C14.2935349,6.37860421 14.0769192,6.37860421 13.9433148,6.51590434 C13.8144819,6.64830089 13.8098807,6.86002024 13.9295112,6.9980827 L13.9433148,7.01311209 L14.7809946,7.87396311 C14.9099921,8.00652875 15.1163745,8.01109998 15.2507088,7.88767679 L15.2648192,7.87396311 L15.2705513,7.86793244 L16.7515118,6.34614699 C16.8915998,6.20218394 16.8915998,5.96877372 16.7515118,5.82481067 Z M11.6771824,6.49778699 L1.50673321,6.49778699 C1.29869677,6.49778699 1.13004991,6.66643385 1.13004991,6.87447029 C1.13004991,7.0762026 1.28863061,7.2408964 1.48793292,7.2506926 L1.50673321,7.25115359 L11.6771824,7.25115359 C11.8852189,7.25115359 12.0538657,7.08250673 12.0538657,6.87447029 C12.0538657,6.67273798 11.895285,6.50804418 11.6959827,6.49824798 L11.6771824,6.49778699 Z"
            id="Shape"
            fill="url(#linearGradient-1)"
          ></path>
          <path
            d="M3.66535455,0 L14.3212732,0 C14.8893375,0 15.3498446,0.460507114 15.3498446,1.02857143 L15.3498446,2.17323665 C15.3498446,2.74130097 14.8893375,3.20180808 14.3212732,3.20180808 L3.66535455,3.20180808 C3.09729024,3.20180808 2.63678312,2.74130097 2.63678312,2.17323665 L2.63678312,1.02857143 C2.63678312,0.460507114 3.09729024,0 3.66535455,0 Z"
            id="Rectangle"
            fillOpacity="0.3"
            fill="#0E4367"
          ></path>
          <path
            d="M3.66535455,10.5471325 L14.3212732,10.5471325 C14.8893375,10.5471325 15.3498446,11.0076396 15.3498446,11.5757039 L15.3498446,12.7203691 C15.3498446,13.2884335 14.8893375,13.7489406 14.3212732,13.7489406 L3.66535455,13.7489406 C3.09729024,13.7489406 2.63678312,13.2884335 2.63678312,12.7203691 L2.63678312,11.5757039 C2.63678312,11.0076396 3.09729024,10.5471325 3.66535455,10.5471325 Z"
            id="Rectangle"
            fillOpacity="0.3"
            fill="#0E4367"
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default NoData;
