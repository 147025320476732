import { Link, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Container from "@mui/material/Container";
import NotFound from "../static/icons/not-found.svg"; // TODO: turn into MUI component
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const NotFoundView = (props) => {
  const location = useLocation();
  const { isFullScreen } = props;
  return (
    <>
      {
        <Container
          sx={{
            bgcolor: "background.default",
            display: "flex",
            height: "100vh",
            width: "100vw",
            alignItems: "center",
            justifyContent: "center",
            position: isFullScreen ? "relative" : undefined,
            top: isFullScreen ? 0 : undefined,
            left: isFullScreen ? 0 : undefined,
            zIndex: isFullScreen ? 2000 : undefined,
            overFlowY: isFullScreen ? "hidden" : undefined,
          }}
        >
          <Stack direction="column" sx={{ alignItems: "center" }}>
            <NotFound />
            <Typography sx={{ mt: 2 }} variant="h4">
              Oops! Page not found
            </Typography>
            <Typography sx={{ mt: 2 }} variant="subtitle1">
              The page you are looking for does not exist, or an error might have occured.
            </Typography>
            <Typography variant="subtitle2">Contact our support team if this error persists.</Typography>
            <Box sx={{ mt: 2 }}>
              <Button component="a" variant="contained" href="mailto:support@agamonhealth.com" target="_blank">
                Contact us
              </Button>
            </Box>
          </Stack>
        </Container>
      }
    </>
  );
  // </Box>
};

export default NotFoundView;
