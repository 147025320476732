import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider } from "notistack";
import PermissionProvider from "./providers/PermissionProvider";
import LocalDataProvider from "./providers/LocalDataProvider";
import ConfigProvider from "./providers/ConfigProvider";
import { ReactQueryDevtools } from "react-query/devtools";
import React from "react";

const queryClient = new QueryClient();

queryClient.setDefaultOptions({
  queries: {
    refetchInterval: false,
    refetchOnWindowFocus: false,
  },
});
const AppWrapper = (props) => {
  // This separation to another component is used in order to pass the theme configurations from the Config provider.
  // TODO: A more elegant solution is preferred.

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: "right", vertical: "top" }}>
          <PermissionProvider>
            <LocalDataProvider>
              <ConfigProvider>{props.children}</ConfigProvider>
            </LocalDataProvider>
          </PermissionProvider>
        </SnackbarProvider>
        {["development", "staging"].includes(process.env.NODE_ENV) && (
          <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
        )}
      </QueryClientProvider>
    </LocalizationProvider>
  );
};

export default AppWrapper;
