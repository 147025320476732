import React, { useState } from "react";
import Box from "@mui/material/Box";
import DevToolsIcon from "./icons/DevToolsIcon";
import Drawer from "@mui/material/Drawer";
import Fab from "@mui/material/Fab";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useConfig from "../providers/useConfig";
import usePermission from "../providers/usePermission";
import useCoordinateAppConfig from "../providers/useCoordinateAppConfig";

export const DevTools = (props) => {
  const {
    isDarkMode,
    openDevTools,
    setIsDarkMode,
    setOpenDevTools,
    setShowDevOptionsPreference,
    showDevOptionsPreference,
  } = useConfig();

  const { reportsTableHiddenColumns, reportsTableColumnsToToggle, setReportsTableHiddenColumns } =
    useCoordinateAppConfig();
  const { devUserRoles, setDevUserRoles, availableDevUserRoles } = usePermission();

  function handleOnClickDevTools(e) {
    setOpenDevTools((prev) => !prev);
  }

  const [fabPlacement, setFabPlacement] = useState("top");

  return (
    <>
      {["development", "staging"].includes(process.env.NODE_ENV) && (
        <Tooltip placement="left" title="Developer tools">
          <Fab
            size="small"
            color="warning"
            sx={{
              position: "fixed",
              margin: "1em",
              [fabPlacement]: "0px",
              right: "0px",
            }}
          >
            <DevToolsIcon onClick={handleOnClickDevTools} />
          </Fab>
        </Tooltip>
      )}
      <Drawer open={openDevTools} onClose={() => setOpenDevTools(false)} sx={{}}>
        <Box
          sx={{
            padding: "1em",
          }}
        >
          <Typography variant="subtitle2">Developer tools</Typography>
          <InputLabel>Fab placement</InputLabel>
          <Select value={fabPlacement} onChange={(e) => setFabPlacement(e.target.value)} fullWidth>
            <MenuItem value="top" key="topRight">
              Top right
            </MenuItem>
            <MenuItem value="bottom" key="topLeft">
              Bottom right
            </MenuItem>
          </Select>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={showDevOptionsPreference}
                  onClick={(e) => setShowDevOptionsPreference(!showDevOptionsPreference)}
                />
              }
              label="Enable in-app developer tools"
            />
          </FormGroup>

          <Typography sx={{ mt: 2 }} variant="subtitle2">
            General
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={isDarkMode} onClick={(e) => setIsDarkMode(!isDarkMode)} />}
              label="Dark mode"
            />
          </FormGroup>

          <Typography sx={{ mt: 2 }} variant="subtitle2">
            Reports table
          </Typography>
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Hidden columns</FormLabel>
            <Select
              sx={{ width: "300px", maxWidth: "300px" }}
              variant="outlined"
              fullWidth
              value={reportsTableHiddenColumns}
              multiple
              onChange={(e) => setReportsTableHiddenColumns(e.target.value)}
            >
              {reportsTableColumnsToToggle.map((colId) => (
                <MenuItem value={colId} selected={reportsTableHiddenColumns.includes(colId)} key={colId}>
                  {colId}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography sx={{ mt: 2 }} variant="subtitle2">
            Dev user
          </Typography>
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Roles</FormLabel>
            <Select
              sx={{ width: "300px", maxWidth: "300px" }}
              variant="outlined"
              fullWidth
              value={devUserRoles}
              multiple
              onChange={(e) => setDevUserRoles(e.target.value)}
            >
              {availableDevUserRoles.map((role) => {
                return (
                  <MenuItem value={role} selected={availableDevUserRoles.includes(role)} key={role}>
                    {role}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Drawer>
    </>
  );
};
