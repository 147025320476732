import "swiper/css";

import { Controller, useForm } from "react-hook-form";
import { FormHelperText, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { InputLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LoadingScreen from "../LoadingScreen";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

const DefaultLoginSlides = (props) => {
  const { swiper, setSwiper, setErrorMessage } = props;
  const [searchParams] = useSearchParams();
  const [flowId, setFlowId] = useState();
  const [csrfToken, setCsrfToken] = useState();
  const [returnTo, setReturnTo] = useState("/");
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [isTotpRequired, setIsTotpRequired] = useState(false);
  const [oidcNodes, setOidcNodes] = useState();
  const { control: controlPassword, handleSubmit: handleSubmitPassword } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { control: controlOidc, handleSubmit: handleSubmitOidc } = useForm();

  const {
    control: controlTotp,
    handleSubmit: handleSubmitTotp,
    setError: setErrorTotp,
  } = useForm({
    defaultValues: {
      totp_code: "",
    },
  });

  async function handleErrorResponse(error) {
    if (error.response?.data) {
      const body = error.response.data;
      if (body.error) {
        const error = body.error;
        if (error.id === "self_service_flow_expired") {
          setErrorMessage("Session expired, please try again.");
          initializeLoginFlow();
        } else if (error.id === "browser_location_change_required") {
          setIsRedirecting(true);
          const redirect_browser_to = body.redirect_browser_to;
          window.location.replace(redirect_browser_to);
          return;
        } else if (error.id === "session_aal2_required") {
          initializeLoginFlow(true);
        } else {
          if (error.message) {
            setErrorMessage(error.message);
          } else {
            setErrorMessage("An unknown error occured.");
          }
        }
      } else if (body.ui) {
        handleUserFacingErrors(body);
      }
    } else {
      setErrorMessage("An unknown error occured.");
    }
  }

  function handleUserFacingErrors(flow) {
    var errorMessages = [];
    if (flow.ui.messages) {
      errorMessages = errorMessages.concat(_.filter(flow.ui.messages, { type: "error" }));
    }
    errorMessages = errorMessages.concat(
      _.chain(flow.ui.nodes)
        .map((node) =>
          node.messages.map((message) => ({
            ...message,
            fieldName: node.attributes.name,
          }))
        )
        .flatten()
        .uniqBy("id")
        .value()
    );
    if (errorMessages.length > 0) {
      if (errorMessages.length === 1) {
        const errorMessage = errorMessages[0];
        if (errorMessage.id === 4000006) {
          setErrorMessage("The provided credentials are invalid.");
        } else {
          setErrorMessage(errorMessage.text);
        }
        if (errorMessage.fieldName) {
          setErrorTotp(errorMessage.fieldName, {
            message: errorMessage.text,
            type: errorMessage.type,
          });
        }
      }
    }
  }

  async function getLoginFlow(flowId) {
    axios
      .get(KRATOS_PUBLIC_API + "/self-service/login/flows", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          id: flowId,
        },
        withCredentials: true,
      })
      .then((res) => {
        extractDataFromFlow(res.data);
      })
      .catch(handleErrorResponse);
  }

  function extractDataFromFlow(flow, totp = false) {
    setFlowId(flow.id);
    const csrfToken = _.find(flow.ui.nodes, {
      attributes: { name: "csrf_token" },
    }).attributes.value;
    setCsrfToken(csrfToken);
    const oidc = _.filter(flow.ui.nodes, {
      group: "oidc",
    });
    if (oidc.length > 0) {
      setOidcNodes(oidc);
    }
    if (totp) {
      setIsTotpRequired(true);
    }
    handleUserFacingErrors(flow);
  }

  async function initializeLoginFlow(totp = false) {
    setCsrfToken(undefined);
    setFlowId(undefined);
    axios
      .get(KRATOS_PUBLIC_API + "/self-service/login/browser", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          aal: !totp ? "aal1" : "aal2",
          refresh: !totp ? true : false,
        },
        withCredentials: true,
      })
      .then(({ data: flow }) => {
        extractDataFromFlow(flow, totp);
        if (totp) {
          // Totp screen is actually another initalized flow.
          swiper.slideNext();
        }
      })
      .catch(handleErrorResponse);
  }

  async function onSubmit(formData, method, oidcProvider = undefined) {
    setErrorMessage();
    await new Promise((resolve) => {
      // UX: makes user sees error message getting cleaned up between attempts.
      setTimeout(() => {
        resolve();
      }, 1000);
    });
    var data;
    if (method === "password") {
      data = {
        identifier: formData.email,
        password: formData.password,
      };
    } else if (method === "totp") {
      data = formData;
    } else if (method === "oidc" && oidcProvider) {
      data = {
        provider: oidcProvider,
      };
    } else {
      throw "No valid method";
    }
    // Send credentials
    await axios
      .post(
        KRATOS_PUBLIC_API + "/self-service/login",
        {
          method,
          csrf_token: csrfToken,
          ...data,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
          params: {
            flow: flowId,
          },
        }
      )
      .then(async (res) => {
        if (res.data.session.active === true) {
          await axios
            .get(KRATOS_PUBLIC_API + "/sessions/whoami", {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              withCredentials: true,
            })
            .then((res) => {
              if (res.data?.active === true) {
                // Redirect if a user is logged in
                window.location.replace(returnTo);
              }
            })
            .catch(handleErrorResponse);
        }
      })
      .catch(handleErrorResponse);
  }

  useEffect(() => {
    const returnToParam = searchParams.get("return_to");
    if (returnToParam) setReturnTo(returnToParam);
    const flowIdParam = searchParams.get("flow");
    if (!flowIdParam) {
      initializeLoginFlow();
    } else {
      getLoginFlow(flowIdParam);
    }
  }, []);

  return (
    <Swiper
      simulateTouch={false}
      touchRatio={0}
      slidesPerView={1}
      enabled={true}
      speed={300} // That's actually the default
      onSwiper={(swiperInstance) => setSwiper(swiperInstance)}
      onSlideChange={(swiperInstance) => swiperInstance.updateAutoHeight(swiperInstance.speed)}
    >
      <SwiperSlide>
        {!flowId && <LoadingScreen />}
        {flowId && (
          <form onSubmit={handleSubmitPassword((data) => onSubmit(data, "password"))}>
            <Stack sx={{ alignItems: "center" }} spacing={2}>
              <Stack sx={{ width: "100%" }} spacing={1}>
                <InputLabel shrink>Email</InputLabel>
                <Controller
                  name="email"
                  control={controlPassword}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <TextField {...field} placeholder="Enter email" fullWidth error={!!fieldState.error} />
                  )}
                />
              </Stack>
              <Stack sx={{ width: "100%" }} spacing={1}>
                <InputLabel shrink>Password</InputLabel>
                <Controller
                  name="password"
                  control={controlPassword}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      placeholder="Enter password"
                      type="password"
                      fullWidth
                      error={!!fieldState.error}
                    />
                  )}
                />
              </Stack>
              <Stack sx={{ width: "50%" }} spacing={1}>
                <Controller
                  name="submit"
                  control={controlPassword}
                  render={({ field, formState: { isSubmitting } }) => (
                    <LoadingButton variant="contained" type="submit" size="large" loading={isSubmitting} fullWidth>
                      Sign in
                    </LoadingButton>
                  )}
                />
                {oidcNodes &&
                  oidcNodes.map((node) => (
                    <Controller
                      name="submit"
                      control={controlOidc}
                      render={({ field, formState: { isSubmitting } }) => (
                        <LoadingButton
                          type="submit"
                          loading={isSubmitting || isRedirecting}
                          onClick={handleSubmitOidc((data) => onSubmit(data, "oidc", node.attributes.value))}
                          fullWidth
                        >
                          {node.meta.label.text}
                        </LoadingButton>
                      )}
                    />
                  ))}
              </Stack>
            </Stack>
          </form>
        )}
      </SwiperSlide>
      <SwiperSlide>
        {!flowId && <LoadingScreen />}
        {flowId && (
          <form onSubmit={handleSubmitTotp((data) => onSubmit(data, "totp"))}>
            <Stack sx={{ alignItems: "center" }} spacing={2}>
              <Stack sx={{ width: "100%" }} spacing={1}>
                <InputLabel shrink>Two-Factor Authentication</InputLabel>
                <Controller
                  name="totp_code"
                  control={controlTotp}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <>
                      <TextField
                        {...field}
                        placeholder="Enter 6 digit code"
                        autoComplete="off"
                        type="text"
                        fullWidth
                        disabled={!isTotpRequired}
                        error={!!fieldState.error}
                      />
                      <FormHelperText>Copy the code from your authentication app.</FormHelperText>
                    </>
                  )}
                />
              </Stack>
              <Stack sx={{ width: "50%" }} spacing={1}>
                <Controller
                  name="submit"
                  control={controlTotp}
                  render={({ field, formState: { isSubmitting } }) => (
                    <LoadingButton variant="contained" type="submit" size="large" loading={isSubmitting} fullWidth>
                      Submit
                    </LoadingButton>
                  )}
                />
              </Stack>
            </Stack>
          </form>
        )}
      </SwiperSlide>
    </Swiper>
  );
};

export default DefaultLoginSlides;
