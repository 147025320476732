// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-container {
  border: none;
}

.ql-container.ql-snow {
  border-left: none;
  border-right: none;
}

.ql-toolbar.ql-snow {
  border-left: none;
  border-right: none;
  border-top: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/editor/editor-styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".ql-container {\n  border: none;\n}\n\n.ql-container.ql-snow {\n  border-left: none;\n  border-right: none;\n}\n\n.ql-toolbar.ql-snow {\n  border-left: none;\n  border-right: none;\n  border-top: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
