import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CloseIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M12,14.0839683 L8.45236387,17.5847929 C7.89135171,18.1384024 6.98177128,18.1384024 6.42075912,17.5847929 C5.85974696,17.0311833 5.85974696,16.1336049 6.42075912,15.5799954 L10.0486247,12 L6.42075912,8.42000458 C5.85974696,7.86639505 5.85974696,6.96881667 6.42075912,6.41520714 C6.98177128,5.86159762 7.89135171,5.86159762 8.45236387,6.41520714 L12,9.91603172 L15.5476361,6.41520714 C16.1086483,5.86159762 17.0182287,5.86159762 17.5792409,6.41520714 C18.140253,6.96881667 18.140253,7.86639505 17.5792409,8.42000458 L13.9513753,12 L17.5792409,15.5799954 C18.140253,16.1336049 18.140253,17.0311833 17.5792409,17.5847929 C17.0182287,18.1384024 16.1086483,18.1384024 15.5476361,17.5847929 L12,14.0839683 Z"></path>
  </SvgIcon>
);

export default CloseIcon;
