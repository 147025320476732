import ApiProvider from "./providers/ApiProvider";
import DialogProvider from "./providers/DialogProvider";
import React from "react";
import { createRoot } from "react-dom/client";
import ReportsTableProvider from "./providers/ReportsTableProvider";
import CoordinateAppConfigProvider from "./providers/CoordinateAppConfigProvider";
import AppWrapper from "./AppWrapper";
import AppContent from "./AppContent";
import useCoordinateAppConfig from "./providers/useCoordinateAppConfig";

const App = (props) => {
  return (
    <AppWrapper>
      <CoordinateAppConfigProvider>
        <ApiProvider>
          <ReportsTableProvider>
            <DialogProvider>
              <AppContent {...{ useAppConfigHook: useCoordinateAppConfig }} />
            </DialogProvider>
          </ReportsTableProvider>
        </ApiProvider>
      </CoordinateAppConfigProvider>
    </AppWrapper>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
