import * as React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const AgamonLogoText = (props) => (
  <SvgIcon {...props} viewBox="0 0 1153 175.19">
    <path
      d="M282.38,0c13.75-0.09,27.39,2.5,40.16,7.61C331,11,343.1,19.07,359,31.74l2.87,2.3l-17.4,16.25l-2.21-1.8
	c-12.45-10.15-22.17-16.69-29-19.57c-9.56-4.21-19.86-6.44-30.3-6.58c-12.2-0.01-24.2,3.05-34.9,8.9
	c-10.57,5.49-19.43,13.8-25.58,24c-5.91,9.63-9.05,20.7-9.06,32c0.03,11.6,3.27,22.97,9.38,32.84c6.26,10.39,15.26,18.86,26,24.5
	c11.22,6,23.77,9.08,36.5,8.95c16,0,29.44-4.42,40.42-13.26c9.72-7.62,16.39-18.48,18.78-30.6h-51.31V87.73h76.61v3.22
	c-0.15,25.64-7.92,46.17-23.34,61.4s-36,22.84-61.62,22.84c-31,0-55.73-10.51-73.91-31.49c-14-16.17-21-34.89-21-56.16
	c-0.02-31.69,17.26-60.85,45.07-76.05C249,3.83,264.79,0,282.38,0z M889.58,0c25.47,0,46.9,8.44,64.1,25.29S979.52,63,979.52,87.54
	c0,24.37-8.61,45.14-25.77,62.13s-38.17,25.52-62.84,25.52c-25,0-46.14-8.49-63.34-25.4s-25.83-37.47-25.83-61.48
	c-0.1-15.62,3.96-30.98,11.75-44.52c7.6-13.43,18.69-24.54,32.11-32.16C858.97,3.93,874.15-0.09,889.58,0z M88,4l78.89,167.11h-24.6
	L131,147.71c-12.77-26.07-44.26-36.86-70.34-24.08c-10.48,5.13-18.95,13.61-24.08,24.08l-11.29,23.44H0L79.92,4H88z M479,4
	l78.89,167.11h-24.6L522,147.71c-12.77-26.08-44.25-36.87-70.33-24.1c-10.49,5.13-18.97,13.61-24.1,24.1l-11.27,23.42H391L470.9,4
	H479z M611.33,4l64.11,127.84L738.91,4h7.48l24.27,167.11h-22.6L733.18,63.78l-53.72,107.37h-8.11L616.87,62.89l-14.79,108.26
	h-22.26L603.86,4H611.33z M1027,4l103.44,117.7V4H1153v167.15h-8.36L1042,55v116.15h-23.29V4H1027z M890.35,22.13
	c-23.3,0.19-44.78,12.6-56.58,32.69c-5.82,10.19-8.8,21.76-8.64,33.49c0,18.23,6.35,33.53,19.12,46.07s28.08,18.79,46.1,18.79
	c11.7,0.09,23.22-2.88,33.4-8.64c9.98-5.52,18.25-13.69,23.9-23.6c5.78-10.17,8.74-21.69,8.59-33.39
	c0.43-23.47-12.19-45.25-32.77-56.54C913.4,25.18,901.98,22.12,890.35,22.13z M474.92,48.78l-24.51,50.74
	c15.75-5.76,33.02-5.76,48.77,0L474.92,48.78z M83.92,48.78L59.47,99.45c15.71-5.83,33-5.83,48.71,0L83.92,48.78z"
    />
  </SvgIcon>
);

export default AgamonLogoText;
