import React, { Fragment } from "react";
import moment from "moment";
import _ from "lodash";

function flattenRecommendations(recommendations) {
  return _.concat(
    recommendations || [],
    _.flatMap(
      _.filter(recommendations, (rec) => rec.joinedOrRecs),
      "joinedOrRecs"
    )
  );
}

function buildRelmclPayloadForVerifiedRecs(recommendations, newStatus) {
  if (newStatus === "verified") {
    const recsWithJoinedOrRecs = _.filter(recommendations, (r) => r.joinedOrRecs);
    return _.flatMap(recsWithJoinedOrRecs, (rec) => {
      const joinedRecIds = _.map(rec.joinedOrRecs, "id");
      const relationsToVerified = _.filter(
        rec.relations,
        (rel) =>
          _.intersection([rel.firstRecommendationId, rel.secondRecommendationId], joinedRecIds).length > 0 &&
          _.isNull(rel.currentStatus)
      );

      return _.map(relationsToVerified, (rel) => ({
        ..._.pick(rel, ["relationId", "latestRelmclId", "latestRelclId"]),
        changes: [{ changedColumn: "status", newValue: "verified" }],
      }));
    });
  } else {
    return [];
  }
}

const generateRecommendationShortDesc = (rec) => {
  const generateTextFromRecAction = (recAction) => {
    if (!recAction) {
      return "";
    } else {
      const categories = recAction.categories;
      return categories.actionType === "Unspecified" ? categories.actionType : categories.actionSubType;
    }
  };

  return `${generateTextFromRecAction(rec.recommendedAction)}${_.join(
    _.map(rec.joinedOrRecs, (jrec) => `/${generateTextFromRecAction(jrec.recommendedAction)}`),
    ""
  )}`;
};

/**
 * Recursively removes keys with empty values (empty strings or null/undefined) from an object.
 * If a nested object becomes empty after removal, the corresponding key is deleted as well.
 *
 * @param {Object} data - The object from which to remove empty fields.
 *
 * @example
 * const nestedData = {
 *   key1: 'value1',
 *   key2: {
 *     nestedKey1: '',
 *     nestedKey2: 'value2'
 *   },
 *   key3: ''
 * };
 *
 * removeEmptyFields(nestedData);
 * console.log(nestedData);
 * // Output:
 * // {
 * //   key1: 'value1',
 * //   key2: {
 * //     nestedKey2: 'value2'
 * //   }
 * // }
 */
function removeEmptyFields(data) {
  if (data && typeof data === "object") {
    for (let key in data) {
      if (data[key] === "" || data[key] === null || data[key] === undefined) {
        // Remove key if value is an empty string, null, or undefined
        delete data[key];
      } else if (typeof data[key] === "object") {
        // Recursively call removeEmptyFields for nested objects
        removeEmptyFields(data[key]);

        // If the nested object is empty after the recursive call, delete the key
        if (Object.keys(data[key]).length === 0) {
          delete data[key];
        }
      }
    }
  }
}

function stringToHslColor(str, saturation = 40, lightness = 70) {
  var hash = 0;
  for (var i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var hue = hash % 120;
  return "hsl(" + hue + ", " + saturation + "%, " + lightness + "%)";
}

const generateMailtoFunction = (recipientEmail, subject, body) => {
  subject = encodeURI(subject);
  body = encodeURI(body);
  return () => {
    window.open(`mailto:${recipientEmail}?subject=${subject}&body=${body}`, "_blank");
  };
};

const getUserDisplayName = (user) => {
  if (user.firstName && !user.lastName) return `${user.firstName}`;
  if (user.firstName && user.lastName) return `${user.firstName} ${user.lastName}`;
  else if (user.username) return user.username;
  return null;
};

const getUserDisplayNameAndUsername = (user) => {
  if (typeof user == "string") {
  }

  const displayName = getUserDisplayName(user);
  return (
    <Fragment>
      {[displayName, user.username].filter(Boolean).map((name, index) => (
        <div key={index}>{name}</div>
      ))}
    </Fragment>
  );
};

const getUserInitials = (user) => {
  if (user.firstName && !user.lastName) return `${user.firstName[0]}`;
  if (user.firstName && user.lastName) return `${user.firstName[0]}${user.lastName[0]}`.toUpperCase();
  else if (user.username) return username[0].toUpperCase();
  return null;
};

function titleCase(str) {
  if (str == null) return str;
  return str.split(" ").map(_.capitalize).join(" ");
}

function formatTime(timeInHours) {
  const duration = moment.duration(timeInHours, "hours");
  return duration.humanize({ d: 100, w: 100 });
}

export function patientName(report, onlyMrn, mrn) {
  let text = "Patient N/A";
  if (onlyMrn) {
    text = mrn;
  } else if (report?.patientFirstName && report?.patientLastName) {
    text = titleCase(`${report?.patientFirstName} ${report?.patientLastName}`);
  }
  if (report?.patientDeceased) {
    text = text + " (Deceased)";
  }
  return text;
}

//duplicated code from api , have to move to  in @agamon/utils
function recWithJoinedOrRecTxt(rec) {
  let recText = recTxt(rec);
  const joinedOrRecsTxt = rec.joinedOrRecs
    ? rec.joinedOrRecs
        .map((jRec) => {
          return "/" + recTxt(jRec);
        })
        .join("")
    : "";
  return recText + joinedOrRecsTxt;
}

function recTxt(rec) {
  let text = "";
  text +=
    rec.recommendedAction.categories.actionType === "Unspecified" ? rec.recommendedAction.categories.actionType : "";
  text += rec.recommendedAction.categories.actionSubType ? rec.recommendedAction.categories.actionSubType : "";
  text +=
    rec.recommendedAction.categories.actionBodyPart && rec.recommendedAction.categories.actionBodyPart !== "Unspecified"
      ? ` (${rec.recommendedAction.categories.actionBodyPart})`
      : "";
  return text;
}

export {
  stringToHslColor,
  formatTime,
  generateMailtoFunction,
  getUserDisplayName,
  getUserInitials,
  getUserDisplayNameAndUsername,
  titleCase,
  removeEmptyFields,
  generateRecommendationShortDesc,
  buildRelmclPayloadForVerifiedRecs,
  flattenRecommendations,
  recTxt,
};
