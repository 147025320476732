import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import CloseIcon from "../../icons/CloseIcon";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import ReactJson from "react-json-view";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";

const RawDataDialog = (props) => {
  const { onClose, json, open } = props;
  const [copyTooltipText, seKToFunc] = useState(null);

  return (
    <Dialog maxWidth="xl" scroll="body" onClose={onClose} open={open}>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        Raw data
        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          <Tooltip title="Close" arrow={true} placement="top">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <ReactJson src={json} indentWidth={2} sortKeys enableClipboard collapsed />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RawDataDialog;
