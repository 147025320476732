import { Box, Button, Collapse, Paper, Stack, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";

import { InputLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Logo from "../icons/Logo";
import axios from "axios";
import { useSnackbar } from "notistack";

const Register = (props) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function onSubmit(data) {
    axios
      .post(
        "http://localhost:4433/admin/identities",
        {
          schema: "default",
          metadata_public: {
            roles: ["verifier", "coordinator", "annotator", "admin", "developer"],
          },
          traits: {
            email: data.email,
            name: {
              firstName: data.firstName,
              lastName: data.lastName,
            },
          },
          csrf_token: data.csrfToken,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
          params: {
            flow: data.flowId,
          },
        }
      )
      .then((res) => {
        enqueueSnackbar(`Registration of user completed`);
        axios
          .post(
            "http://localhost:4433/admin/recovery/link",
            {
              identity_id: data.email,
              expires_in: "24h",
              csrf_token: data.csrfToken,
            },
            {
              withCredentials: true,
              headers: {
                Accept: "application/json",
              },
              params: {
                flow: data.flowId,
              },
            }
          )
          .then((res) => {
            console.log(res);
            enqueueSnackbar(`Link`);
          });
      })
      .catch((error) => {});
  }

  useEffect(() => {
    axios
      .get("http://localhost:4433/self-service/registration/browser", {
        headers: {
          Accept: "application/json",
        },
        withCredentials: true,
      })
      .then((res) => {
        const flowId = res.data.id;
        const csrfToken = res.data.ui.nodes[0].attributes.value;
        setValue("flowId", flowId);
        setValue("csrfToken", csrfToken);
        enqueueSnackbar(`Registration flow initialized. flowId: ${flowId}`);
      });
  }, []);

  return (
    <Box
      sx={{
        backgroundImage: `url(${Image})`,
        backgroundPosition: "bottom",
        backgroundRepeat: "repeat-x",
      }}
    >
      <Stack direction="row" sx={{ justifyContent: "center" }}>
        <Stack sx={{ justifyContent: "center", height: "100vh" }}>
          <Paper sx={{ p: 4 }}>
            <Stack sx={{ p: 5, alignItems: "center" }} spacing={2}>
              <Logo sx={{ fontSize: "8vh" }} />
              <Typography variant="h4">Welcome to Agamon</Typography>
            </Stack>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack sx={{ alignItems: "center" }} spacing={3}>
                <Stack sx={{ width: "100%" }} spacing={1}>
                  <InputLabel shrink>First name</InputLabel>
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <TextField {...field} placeholder="First name" fullWidth error={!!fieldState.error} />
                    )}
                  />
                </Stack>
                <Stack sx={{ width: "100%" }} spacing={1}>
                  <InputLabel shrink>Last name</InputLabel>
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <TextField {...field} placeholder="Last name" fullWidth error={!!fieldState.error} />
                    )}
                  />
                </Stack>
                <Stack sx={{ width: "100%" }} spacing={1}>
                  <InputLabel shrink>Email</InputLabel>
                  <Controller
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <TextField {...field} placeholder="Email" type="email" fullWidth error={!!fieldState.error} />
                    )}
                  />
                </Stack>
                {/* <Stack sx={{ width: "100%" }} spacing={1}>
                  <InputLabel shrink>Password</InputLabel>
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        placeholder="Password"
                        type="password"
                        fullWidth
                        error={!!fieldState.error}
                      />
                    )}
                  />
                </Stack> */}
                <Stack sx={{ width: "100%" }} spacing={1}>
                  <InputLabel shrink>User roles</InputLabel>
                </Stack>
                <Controller
                  name="submit"
                  control={control}
                  render={({ field, fieldState }) => (
                    <LoadingButton variant="contained" type="submit" loading={fieldState.isSubmitting} fullWidth>
                      Sign in
                    </LoadingButton>
                  )}
                />
              </Stack>
            </form>
          </Paper>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Register;
