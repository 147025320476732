import { Stack, InputLabel, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import React from "react";
import ReactHookFormSelect from "../../../../ReactFormHookSelect";
import _ from "lodash";

import useConfig from "../../../../../providers/useConfig";
import useCoordinateAppConfig from "../../../../../providers/useCoordinateAppConfig";

export default function StatusClosedFields({ formVarsForFields }) {
  const { control, errors, watch, resetField } = formVarsForFields;
  const { recommendationClosureReasons } = useCoordinateAppConfig();
  const selectedClosureReason = _.find(recommendationClosureReasons, {
    value: watch("extraFields.formFields.closedReason"),
  });

  const onClosedReasonChange = () => {
    resetField("arrivedOn");
  };

  return (
    <>
      <Stack direction="row">
        <Stack sx={{ mr: 2 }}>
          <InputLabel shrink>Closed reason</InputLabel>
          <ReactHookFormSelect
            onChange={onClosedReasonChange}
            name="extraFields.formFields.closedReason"
            control={control}
            defaultValue=""
            variant="outlined"
            rules={{ required: true }}
            options={recommendationClosureReasons}
            error={!!errors.closedReason}
          />
        </Stack>
        {selectedClosureReason?.dateProperties && (
          <Stack>
            <InputLabel shrink>{selectedClosureReason.dateProperties.dateLabel}</InputLabel>
            <Controller
              render={({ field, fieldState }) => {
                return (
                  <TextField variant="outlined" type="date" {...field} {...fieldState} error={!!fieldState.error} />
                );
              }}
              type="date"
              variant="outlined"
              rules={{
                required: true,
              }}
              control={control}
              name="extraFields.formFields.arrivedOn"
              defaultValue=""
            />
          </Stack>
        )}
      </Stack>
      <Stack sx={{ mt: 2 }}>
        <InputLabel shrink>Comment</InputLabel>
        <Controller
          render={({ field, fieldState }) => (
            <TextField
              variant="outlined"
              defaultValue=""
              placeholder="Add a comment"
              {...field}
              multiline
              error={!!fieldState.error}
            />
          )}
          name="extraFields.formFields.comment"
          control={control}
          error={errors.comment}
        />
      </Stack>
    </>
  );
}
