import { Box, Fade, Zoom } from "@mui/material";
import React, { useEffect, useState } from "react";

import Logo from "./icons/Logo";
import _ from "lodash";

const Splash = (props) => {
  const { show } = props;
  const fadingDuration = _.defaultTo(props.fadingDuration, 1000);
  const fadeOutDelay = _.defaultTo(props.fadeOutDelay, 2000);

  const [logoFade, setLogoFade] = useState(true);
  const [logoZoom, setLogoZoom] = useState(true);
  const [boxFade, setBoxFade] = useState(true);

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setBoxFade(false);
        setLogoZoom(false);
      }, fadeOutDelay);
    }
  }, [show]);

  return (
    <Fade in={boxFade} appear={false} timeout={fadingDuration} unmountOnExit>
      <Box
        sx={{
          bgcolor: "background.default",
          zIndex: 2000,
          display: "flex",
          justifyContent: "center",
          overFlowY: "hidden",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <Fade in={logoFade}>
          <Box>
            <Zoom in={logoZoom} timeout={fadingDuration}>
              <Box>
                <Logo sx={{ fontSize: "20vh", color: "primary.main" }} />
              </Box>
            </Zoom>
          </Box>
        </Fade>
      </Box>
    </Fade>
  );
};

export default Splash;
