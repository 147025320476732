import { Typography } from "@mui/material";
import React from "react";

export const FormatMessages = ({ messages }) => {
  if (!Array.isArray(messages) || messages.length === 0) {
    return null;
  }

  if (messages.length === 1) {
    return <Typography>{messages[0].text}</Typography>;
  }

  return (
    <ul>
      {messages.map((message, index) => (
        <li key={index}>
          <Typography>{message.text}</Typography>
        </li>
      ))}
    </ul>
  );
};
