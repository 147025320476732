import * as React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const TrashIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M4.05498454,9 L3.69230769,9 C3.14279152,9 2.68653846,8.55712039 2.68653846,8 C2.68653846,7.43904706 3.13135476,7 3.69230769,7 L8.14807692,7 L8.14807692,5 C8.14807692,3.8954305 9.04350742,3 10.1480769,3 L13.8519231,3 C14.9564926,3 15.8519231,3.8954305 15.8519231,5 L15.8519231,7 L20.3076923,7 C20.8686452,7 21.3134615,7.43904706 21.3134615,8 C21.3134615,8.56095294 20.8686452,9 20.3076923,9 L19.9450146,9 L18.3712311,20.122264 C18.2987731,20.6294699 17.8795727,21 17.3692308,21 L6.63076923,21 C6.11333574,21 5.67573985,20.6198163 5.62809644,20.117322 L4.05498454,9 Z M10,5 L10,7 L14,7 L14,5 L10,5 Z M11,16.5 L11,11.5 C11,10.9477153 10.5522847,10.5 10,10.5 C9.44771525,10.5 9,10.9477153 9,11.5 L9,16.5 C9,17.0522847 9.44771525,17.5 10,17.5 C10.5522847,17.5 11,17.0522847 11,16.5 Z M15,16.5 L15,11.5 C15,10.9477153 14.5522847,10.5 14,10.5 C13.4477153,10.5 13,10.9477153 13,11.5 L13,16.5 C13,17.0522847 13.4477153,17.5 14,17.5 C14.5522847,17.5 15,17.0522847 15,16.5 Z" />
  </SvgIcon>
);

export default TrashIcon;
