import React, { useState } from "react";
import ReportsTableContext from "./ReportsTableContext";
import _ from "lodash";

const ReportsTableProvider = (props) => {
  const [reportsListData, setReportsListData] = useState();
  const [reportsKeys, setReportsKeys] = useState();

  function getSequentialRptKeys(prevRptKeys, currentRptKeys, nextRptKeys) {
    if (_.isNull(prevRptKeys) && _.isNull(nextRptKeys)) {
      const prev = getRptKeysAtOffset(currentRptKeys, -1, true);
      const next = getRptKeysAtOffset(currentRptKeys, 1, false);
      return { prev, current: currentRptKeys, next };
    }
    return { prev: prevRptKeys, current: currentRptKeys, next: nextRptKeys };
  }

  function getRptKeysAtOffset(currentRptKeys, offset, shouldFetchNext) {
    if (reportsKeys) {
      const currentIndex = _.findIndex(reportsKeys, (o) => o.reportTextId === currentRptKeys.reportTextId);
      const indexAtOffset = currentIndex + offset;

      if (currentIndex === reportsKeys.length - 6 && shouldFetchNext) {
        reportsListData.fetchNextPage();
      }
      if (indexAtOffset < 0 || indexAtOffset > reportsKeys.length - 1 || currentIndex === -1) {
        return null;
      }
      return reportsKeys[indexAtOffset];
    }
  }

  return (
    <ReportsTableContext.Provider
      value={{
        setReportsListData,
        setReportsKeys,
        reportsKeys,
        getSequentialRptKeys,
      }}
    >
      {props.children}
    </ReportsTableContext.Provider>
  );
};

export default ReportsTableProvider;
