import React, { useEffect, useState } from "react";
import LocalDataContext from "./LocalDataContext";
import usePermission from "./usePermission";

const LocalDataProvider = ({ children }) => {
  const [progressCounter, setProgressCounter] = useState(0);
  const { user } = usePermission();

  const progressDataKey = user?.username;

  useEffect(() => {
    if (!user) return;
    const progressData = localStorage.getItem(progressDataKey);
    if (!progressData) return;

    const { lastUpdated, count } = JSON.parse(progressData);
    if (lastUpdated === new Date().toDateString()) {
      setProgressCounter(count);
    }
  }, [user]);

  function updateProgressCounter() {
    localStorage.setItem(
      progressDataKey,
      JSON.stringify({ count: progressCounter + 1, lastUpdated: new Date().toDateString() })
    );
    setProgressCounter((count) => count + 1);
  }

  return (
    <LocalDataContext.Provider value={{ progressCounter, updateProgressCounter }}>{children}</LocalDataContext.Provider>
  );
};

export default LocalDataProvider;
