import useConfig from "./providers/useConfig";
import usePermission from "./providers/usePermission";
import useDialog from "./providers/useDialog";
import StylesProvider from "@mui/styles/StylesProvider";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import SvgStylingDefs from "./static/icons/svg-styling-defs.svg";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "./components/ErrorBoundary";
import Splash from "./components/Splash";
import NotFoundView from "./components/NotFoundView";
import AppRoutes from "./generated/AppRoutes";
import { DevTools } from "./components/DevTools";
import axios from "axios";
import React from "react";
import _ from "lodash";

const AppContent = (props) => {
  const { useAppConfigHook } = props;
  const { muiTheme } = useConfig();
  const { isConfSet } = useAppConfigHook();
  const { user, isError } = usePermission();
  const { Dialogs } = useDialog();

  if (muiTheme === null) {
    return <></>;
  }
  return (
    <>
      <StylesProvider injectFirst>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            <Box hidden>
              <SvgStylingDefs />
            </Box>
            <Box
              sx={{
                width: "100vw",
                height: "100vh",
                overflowY: "hidden",
              }}
            >
              <BrowserRouter basename={ROUTES_BASENAME}>
                <ErrorBoundary user={user}>
                  <Splash show={(_.isNil(user) || !isConfSet) && APPNAME !== "sso"} />
                  {isError && <NotFoundView isFullScreen={true} />}
                  {((user && isConfSet) || APPNAME === "sso") && (
                    <>
                      {Dialogs && <Dialogs />}
                      <AppRoutes />
                      {["development", "staging"].includes(process.env.NODE_ENV) && APPNAME !== "physician-portal" && (
                        <DevTools />
                      )}
                    </>
                  )}
                </ErrorBoundary>
              </BrowserRouter>
            </Box>
          </ThemeProvider>
        </StyledEngineProvider>
      </StylesProvider>
    </>
  );
};

if (process.env.NODE_ENV !== "development" && APPNAME !== "sso") {
  // Should probably be more granular, i.e. "if we're in a setup that uses CSRF tokens/Auth service".
  // A CSRF token is generated prior to any data-altering request.
  axios.interceptors.request.use(
    async function (request) {
      if (["post", "put", "delete", "patch"].includes(request.method)) {
        const {
          data: { token },
        } = await axios({
          method: "GET",
          url: AUTH_API + "/csrf",
          withCredentials: true,
        });
        request.headers["CSRF-Token"] = token;
        request.xsrfHeaderName = "CSRF-Token";
      }
      return request;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
}

export default AppContent;
