import { Stack, Typography } from "@mui/material";

import React from "react";

const EmptyState = (props) => {
  const { title, subtitle, Graphics } = props;
  return (
    <Stack
      direction="column"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
      spacing={1}
    >
      {Graphics && <Graphics sx={{ fontSize: "100px" }} />}
      <Typography sx={{ mt: 1 }} variant="h5">
        {title}
      </Typography>
      <Typography variant="subtitle2">{subtitle}</Typography>
    </Stack>
  );
};

export default EmptyState;
