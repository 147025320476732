import React, { useState } from "react";

import AssignDialog from "../components/coordinate/manage/AssignDialog";
import CommunicateDialog from "../components/coordinate/manage/CommunicateDialog";
import DialogContext from "./DialogContext";
import RawDataDialog from "../components/coordinate/manage/RawDataDialog";
import SessionTimeoutDialog from "../components/SessionTimeoutDialog";
import { useIdleTimer } from "react-idle-timer";
import usePermission from "./usePermission";
import LogoutErrorDialog from "../components/LogoutErrorDialog";

const DialogProvider = ({ children }) => {
  const [communicateDialogOpen, setCommunicateDialogOpen] = useState(false);
  const [communicateDialogSelectedRecommendations, setCommunicateDialogSelectedRecommendations] = useState([]);
  const [communicateDialogSelectedReports, setCommunicateDialogSelectedReports] = useState([]);
  const [physicianRole, setPhysicianRole] = useState();
  const [isActive, setIsActive] = useState();

  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [assignDialogSelectedRecommendations, setAssignDialogSelectedRecommendations] = useState([]);
  const [assignDialogSelectedReports, setAssignDialogSelectedReports] = useState([]);
  const [isBatchCommunication, setIsBatchCommunication] = useState();
  const [sessionTimeoutDialogOpen, setSessionTimeoutDialogOpen] = useState(false);
  const { logout } = usePermission();

  const [rawDataDialogOpen, setRawDataDialogOpen] = useState(false);
  const [jsonObject, setJsonObject] = useState(false);

  const [logoutErrorDialogOpen, setLogoutErrorDialogOpen] = useState(false);

  function onIdle() {
    logout(() => {
      closeSessionTimeoutDialog();
      openLogoutErrorDialog();
    });
  }

  function openLogoutErrorDialog() {
    setLogoutErrorDialogOpen(true);
  }

  function onPrompt() {
    openSessionTimeoutDialog();
  }

  const { reset, getRemainingTime } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: 1000 * 60 * 15,
    promptBeforeIdle: 1000 * 60 * 1,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    syncTimers: 0,
    disabled: ["development"].includes(process.env.NODE_ENV) || APPNAME === "sso",
  });

  function openCommunicateDialog(
    selectedRecommendations,
    selectedReports,
    physicianRole,
    isActive,
    isBatchCommunication
  ) {
    setCommunicateDialogOpen(true);
    setCommunicateDialogSelectedRecommendations(selectedRecommendations);
    setCommunicateDialogSelectedReports(selectedReports);
    setPhysicianRole(physicianRole);
    setIsActive(isActive);
    setIsBatchCommunication(isBatchCommunication);
  }

  function closeCommunicateDialog() {
    setCommunicateDialogOpen(false);
    setCommunicateDialogSelectedRecommendations([]);
    setCommunicateDialogSelectedReports([]);
  }

  function openAssignDialog(selectedRecommendations, selectedReports) {
    setAssignDialogOpen(true);
    setAssignDialogSelectedRecommendations(selectedRecommendations);
    setAssignDialogSelectedReports(selectedReports);
  }

  function closeAssignDialog() {
    setAssignDialogOpen(false);
    setAssignDialogSelectedRecommendations([]);
    setAssignDialogSelectedReports([]);
  }

  function openSessionTimeoutDialog() {
    setSessionTimeoutDialogOpen(true);
  }

  function closeSessionTimeoutDialog() {
    setSessionTimeoutDialogOpen(false);
  }

  function openRawDataDialog(jsonObject) {
    setRawDataDialogOpen(true);
    setJsonObject(jsonObject);
  }

  function closeRawDataDialog() {
    setRawDataDialogOpen(false);
    setJsonObject(null);
  }

  function handleOnClickContinueSession(e) {
    reset();
    closeSessionTimeoutDialog();
  }

  const Dialogs = (props) => {
    return (
      <>
        {
          // TODO: when deploying without manage module, one should avoid even bundling the below dialogs (ie avoid
          //  importing them)
          _.includes(MODULES, "manage") && (
            <>
              <CommunicateDialog
                onClose={closeCommunicateDialog}
                open={communicateDialogOpen}
                selectedReports={communicateDialogSelectedReports}
                selectedRecommendations={communicateDialogSelectedRecommendations}
                setSelectedReports={setCommunicateDialogSelectedReports}
                setSelectedRecommendations={setCommunicateDialogSelectedRecommendations}
                physicianRole={physicianRole}
                isActive={isActive}
                isBatchCommunication={isBatchCommunication}
              />
              <AssignDialog
                onClose={closeAssignDialog}
                open={assignDialogOpen}
                selectedReports={assignDialogSelectedReports}
                selectedRecommendations={assignDialogSelectedRecommendations}
              />
              <RawDataDialog open={rawDataDialogOpen} json={jsonObject} onClose={() => setRawDataDialogOpen(false)} />
              <LogoutErrorDialog open={logoutErrorDialogOpen} />
              <SessionTimeoutDialog
                open={sessionTimeoutDialogOpen}
                handleOnClickContinueSession={handleOnClickContinueSession}
              />
            </>
          )
        }
        <></>
      </>
    );
  };

  return (
    <DialogContext.Provider
      value={{
        assignDialogOpen,
        setAssignDialogOpen,
        communicateDialogOpen,
        setCommunicateDialogOpen,
        openCommunicateDialog,
        closeCommunicateDialog,
        Dialogs,
        openAssignDialog,
        openRawDataDialog,
        closeRawDataDialog,
        openLogoutErrorDialog,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};

export default DialogProvider;
