import axios from "axios";
import _ from "lodash";
export function logError(error, errorInfo) {
  axios({
    method: "POST",
    url: `${API}/log/ui-error`,
    withCredentials: true,
    data: {
      // https://stackoverflow.com/q/18391212 (specifically https://stackoverflow.com/a/26199752)
      error: _.pick(error, Object.getOwnPropertyNames(error)),
      errorInfo,
    },
  }).catch((err) => {
    console.error("Failed to send error log to backend:", err);
  });
}
