import React, { useEffect, useState } from "react";

import PermissionContext from "./PermissionContext";
import _ from "lodash";
import axios from "axios";

// This provider is intended to be surrounding the whole application.
// It should receive a method to fetch permissions as parameter
const PermissionProvider = ({ fetchPermission, children }) => {
  const [user, setUser] = useState(null);
  const [isError, setIsError] = useState(null);
  const [authenticating, setAuthenticating] = useState(false);
  const [permissions, setPermissions] = useState();
  const availableDevUserRoles = ["admin", "annotator", "assigner", "coordinator", "developer", "verifier"];
  const [devUserRoles, setDevUserRoles] = useState(availableDevUserRoles);
  const [devUser, setDevUser] = useState({
    firstName: "John",
    lastName: "Doe",
    username: "dev@agamonhealth.com",
    roles: devUserRoles,
    clientIds: [350],
  });

  useEffect(() => {
    setDevUser((prev) => ({ ...prev, roles: devUserRoles }));
  }, [devUserRoles]);

  useEffect(() => {
    if (user && devUser && ["development", "staging"].includes(process.env.NODE_ENV)) {
      setUser(devUser);
    }
  }, [devUser]);

  // Creates a method that returns whether the requested permission is granted to the current user
  async function isAllowedTo(permission) {
    if (Object.keys(cache).includes(permission)) {
      return cache[permission];
    }
    let isAllowed;
    if (fetchPermission) {
      // TODO: Use react-query to fetch a permission.
    } else {
      if (permissions) {
        isAllowed = permissions[permission];
      } else {
        isAllowed = false;
      }
    }
    cache[permission] = isAllowed;
    return isAllowed;
  }

  async function logout(onLogoutError) {
    try {
      let response = await axios.get(KRATOS_PUBLIC_API + "/self-service/logout/browser", {
        withCredentials: true,
      });
      if (response.status === 200) {
        const logoutToken = response.data.logout_token;
        response = await axios.get(KRATOS_PUBLIC_API + "/self-service/logout", {
          params: {
            token: logoutToken,
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });
        if (response.status === 204) {
          window.location.reload();
        } else {
          console.error(`Unknown response status from /self-service/logout- ${response.status}`);
          onLogoutError();
        }
      } else {
        console.error(`Unknown response status - ${response.status}`);
        onLogoutError();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          window.location.reload();
        } else {
          onLogoutError();
        }
      } else {
        console.error(`Unexpected error while logging out - ${error}`);
        onLogoutError();
      }
    }
  }

  function authenticate(data) {
    return new Promise((resolve, reject) => {
      setAuthenticating(true);
      // as the login call should not get the /csrf interceptor, a new axios instance is created.
      // See https://stackoverflow.com/questions/63423209/how-to-ignore-interceptors-in-axios-as-a-parameter
      const unInterceptedAxiosInstance = axios.create();
      unInterceptedAxiosInstance
        .post(AUTH_API + "/api/users/login", data, { withCredentials: true })
        .then((res) => {
          setAuthenticating(false);
          if (res.data.success) {
            setUser(_.omit(res.data, "success"));
          } else {
            setUser(null);
          }
          resolve(res.data.success);
        })
        .catch((err) => {
          setAuthenticating(false);
          if (err.response && err.response.status === 401) {
            reject("Wrong username or password");
          } else {
            reject("Something went wrong");
          }
        });
    });
  }

  // TODO: Write a function in the auth server that retrieves this information per user.
  useEffect(() => {
    if (user) {
      setPermissions({
        verifyRecommendations: user.roles.includes("verifier"),
      });
    }
  }, [user]);

  useEffect(() => {
    if (APPNAME !== "sso") {
      (async function () {
        axios
          .get(API + "/whoami", {
            withCredentials: true,
          })
          .then(({ data: user }) => {
            setUser(user);
            // axios.defaults.headers.common["X-User"] = JSON.stringify(
            //   _.omit(user, "success")
            // );
          })
          .catch((error) => {
            console.error(error);
          });
      })();
    }
  }, []);

  const cache = {};

  // This component will render its children wrapped around a PermissionContext's provider whose
  // value is set to the method defined above
  return (
    <PermissionContext.Provider
      value={{
        user,
        isError,
        isAllowedTo,
        logout,
        authenticate,
        permissions,
        devUser,
        devUserRoles,
        availableDevUserRoles,
        setDevUserRoles,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;
