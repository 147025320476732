import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import usePermission from "../../../providers/usePermission";
import _ from "lodash";
import useApi from "../../../providers/useApi";

const AssignDialog = (props) => {
  const { onClose, selectedRecommendations, selectedReports, open } = props;
  const { useUpdateEntityData } = useApi();
  const { mutate } = useUpdateEntityData();
  const { user } = usePermission();
  const handleConfirm = (recs) => {
    const recommendationsToChange = _.concat(
      recs,
      _.flatMap(
        _.filter(recs, (r) => r.joinedOrRecs),
        "joinedOrRecs"
      )
    );
    const payload = {
      rmcl: _.map(recommendationsToChange, (rec) => ({
        ..._.pick(rec, ["recommendationId", "latestRclId", "latestRmclId"]),
        changes: [
          {
            changedType: "assigned_to",
            extraFields: { assigned_to: user.username },
          },
        ],
      })),
    };
    mutate({ entityType: "recommendation", payload });
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Assignment confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to assign {selectedReports.length} reports ({selectedRecommendations.length}{" "}
          recommendations) to yourself?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton variant="contained" onClick={(e) => handleConfirm(selectedRecommendations)} autoFocus>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AssignDialog;
