import "draft-js/dist/Draft.css";

import { pdfjs } from "react-pdf";
import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useQuery } from "react-query";
import { parsePhoneNumber } from "libphonenumber-js";
import {
  Alert,
  Box,
  Card,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  List,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close, ErrorOutline as ErrorOutlineIcon, Fax as FaxIcon, Notes as NotesIcon } from "@mui/icons-material";
import TrashIcon from "../../icons/TrashIcon";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import qs from "qs";
import useApi from "../../../providers/useApi";
import { useSnackbar } from "notistack";
import StatusClosedFields from "./interaction-form/recommendation/StatusClosedFields";
import { useForm } from "react-hook-form";
import { removeEmptyFields, titleCase } from "../../../utils/auxiliary";
import Editor from "../../editor/Editor";
import { PreviewText } from "./PreviewText";
import { PreviewFax } from "./PreviewFax";

// Sets the URL for the PDF.js worker script to the corresponding version on the unpkg CDN.
// This worker script offloads intensive tasks to a separate thread, enhancing performance when processing PDF files.
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const gettingAllRecIdsFromRec = (rec) => _.concat([rec.id], _.map(rec.joinedOrRecs, "id"));

const CommunicateDialog = (props) => {
  const {
    onClose,
    open,
    selectedRecommendations: initiallySelectedRecs,
    selectedReports,
    physicianRole,
    isActive,
    isBatchCommunication,
  } = props;
  const [selectedFormat, setSelectedFormat] = useState();
  const [physicianValue, setPhysicianValue] = useState();
  const [faxNumber, setFaxNumber] = useState();
  const [templateValue, setTemplateValue] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [infoMessage, setInfoMessage] = useState();
  const [documentErrorMessage, setDocumentErrorMessage] = useState();
  const [formInfoMessage, setFormInfoMessage] = useState();
  const [selectedRecs, setSelectedRecs] = useState(); // Selected recommendations after user clicks on checkboxes
  const [selectedRecsWithJoinedOrRecs, setSelectedRecsWithJoinedOrRecs] = useState();
  const [groupsKeys, setGroupsKeys] = useState(); // referringPhysicianComputedId-facilityId
  const [groupedReports, setGroupedReports] = useState(); // Grouped by referring physician and facility
  const [groupedSelectedRecsWithJoinedOrRecs, setGroupedSelectedRecsWithJoinedOrRecs] = useState(); // Grouped by referring physician and facility
  const [recsToSendPerDocument, setRecsToSendPerDocument] = useState(); // Payload to send to backend
  const { useSendFax } = useApi();
  const { closeSnackbar } = useSnackbar();
  const [editableFieldsToSend, setEditableFieldsToSend] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [isCloseTicketChecked, setIsCloseTicketChecked] = useState();
  const handleEditableFieldsChange = (name, type, value) => {
    setEditableFieldsToSend((prev) => {
      return { ...prev, [name]: { type, value } };
    });
  };
  const { mutate: mutateSendFax, isLoading: isLoadingSendFax } = useSendFax(
    onClose,
    () =>
      setErrorMessage("Couldn't send faxes. Please try again in a few minutes, or contact us if the problem persists."),
    selectedReports
  );
  const {
    handleSubmit,
    control,
    watch,
    resetField,
    formState: { errors },
  } = useForm();

  const physiciansToSkip = useMemo(() => {
    let physiciansToSkip = []; // Some referring physician's fax number may be missing or invalid, we'd like to present them but not send. Also, if physician is inactive, we should disable sending faxes to them.
    for (const key in groupedReports) {
      const sampledReport = groupedReports[key][0];
      const faxNumberToVerify = sampledReport?.[physicianRole].facility?.faxNumber;
      const isFaxConfirmed = sampledReport?.[physicianRole].facility?.contactInfoConfirmed;
      let isInactive;
      if (physicianRole === "rp") {
        isInactive = !sampledReport?.rp.attributes?.active;
      }

      if (physicianRole === "rp" && isInactive) {
        physiciansToSkip.push({
          reportGroupKey: key,
          reason: ` is marked "inactive" on our system`,
        });
      } else if (!faxNumberToVerify) {
        physiciansToSkip.push({
          reportGroupKey: key,
          reason: "'s fax number is missing",
        });
      } else if (!isFaxConfirmed) {
        physiciansToSkip.push({
          reportGroupKey: key,
          reason: "'s fax number is unconfirmed",
        });
      } else {
        const parsedFaxNumber = parsePhoneNumber(faxNumberToVerify, "US");
        if (!parsedFaxNumber?.isValid()) {
          physiciansToSkip.push({
            reportGroupKey: key,
            reason: "'s fax number is invalid",
          });
        }
      }
    }
    return physiciansToSkip;
  }, [groupedReports, faxNumber]);

  const reportGroupKeysOfPhysiciansToSkip = physiciansToSkip.map((p) => p.reportGroupKey);

  const {
    isLoading: isLoadingFormats,
    data: formats,
    isError: isErrorFormats,
    refetch: refetchFormats,
    isRefetching: isRefetchingFormats,
  } = useQuery(
    ["formats"],
    () =>
      axios.get(API + "/documents/formats", {
        withCredentials: true,
      }),
    {
      select: (data) => data.data,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    }
  );

  function handleRpChange(event) {
    setPhysicianValue(event.target.value);
  }

  function handleTemplateChange(event) {
    setTemplateValue(event.target.value);
  }

  function handleCheckboxOnChange(report, rec) {
    const findRec = _.find(selectedRecs, { id: rec.id });
    if (findRec) {
      setSelectedRecs(_.filter(selectedRecs, (r) => r.id != rec.id));
    } else {
      setSelectedRecs([...selectedRecs, rec]);
    }
  }

  function handleSendFax(data) {
    let subsequentChanges;

    if (data?.extraFields) {
      removeEmptyFields(data.extraFields);
      if (!_.isEmpty(data.extraFields)) {
        subsequentChanges = [{ changeType: "status", ...data.extraFields, status: "closed" }];
      }
    }
    setErrorMessage(undefined);
    closeSnackbar();

    //TODO: support multiple documents editableFields
    // in case of communication to PCP, fax number is selected by the user and sent with the payload.
    // when communicating to multiple RPs/ single RP on batch communication this behaviour is not supported, and fax number shouldn't be sent.

    const payloadSendFax = recsToSendPerDocument.map((recs) => ({
      ...(isBatchCommunication ? {} : { subsequentChanges }),
      recsToSend: recs,
      editableFields: editableFieldsToSend,
      physicianRole,
      ...(physicianRole === "pcp" && { faxNumber }),
    }));
    mutateSendFax(payloadSendFax);
  }

  useEffect(() => {
    if (formats && !selectedFormat) {
      for (const fmt of formats) {
        if (fmt.default === true) {
          setSelectedFormat(fmt);
          break;
        }
      }
    }
  }, [formats]);

  useEffect(() => {
    if (formats && selectedFormat) {
      for (const tmplt of selectedFormat.templates) {
        if (tmplt.default === true) {
          setTemplateValue(tmplt.name);
          break;
        }
      }
    }
  }, [selectedFormat]);

  useEffect(() => {
    if (selectedRecs) {
      let array = [...selectedRecs];
      for (const rec of selectedRecs) {
        if (rec.joinedOrRecs) {
          array = [...array, ...rec.joinedOrRecs];
        }
      }
      array = _.uniqBy(array, "id");
      setSelectedRecsWithJoinedOrRecs(array);
    }
  }, [selectedRecs]);

  useEffect(() => {
    if (initiallySelectedRecs && selectedReports) {
      setSelectedRecs([...initiallySelectedRecs]);
      setGroupedReports(
        _.groupBy(
          selectedReports,
          (report) => `${report[`${physicianRole}.id`]}-${report[`${physicianRole}.facility.id`]}`
        )
      );
    }
  }, [initiallySelectedRecs, selectedReports]);

  useEffect(() => {
    if (groupedReports) {
      const keys = Object.keys(groupedReports);
      setGroupsKeys(keys);
      setPhysicianValue(keys[0]);
    }
  }, [groupedReports]);

  useEffect(() => {
    if (groupsKeys && selectedRecsWithJoinedOrRecs) {
      let groupedSelectedRecommendationsTemp = {};
      for (const key of groupsKeys) {
        groupedSelectedRecommendationsTemp[key] = _.flatten(
          groupedReports[key].map((rep) =>
            rep.recommendations.filter((rec) => selectedRecsWithJoinedOrRecs.map((r) => r.id).includes(rec.id))
          )
        );
      }
      setGroupedSelectedRecsWithJoinedOrRecs(groupedSelectedRecommendationsTemp);
    }
  }, [groupsKeys, selectedRecsWithJoinedOrRecs]);

  useEffect(() => {
    if (groupedSelectedRecsWithJoinedOrRecs) {
      let groupedRecsAfterFilteringSkippedPhysicians = {};
      for (const key in groupedSelectedRecsWithJoinedOrRecs) {
        if (!reportGroupKeysOfPhysiciansToSkip.includes(key) && groupedSelectedRecsWithJoinedOrRecs[key].length > 0) {
          groupedRecsAfterFilteringSkippedPhysicians[key] = groupedSelectedRecsWithJoinedOrRecs[key];
        }
      }
      let recsToSendPerDocumentTemp = {};
      recsToSendPerDocumentTemp = _.map(_.values(groupedRecsAfterFilteringSkippedPhysicians), (recs) =>
        _.flatMap(recs, (rec) =>
          _.map(_.concat(rec, rec.joinedOrRecs || []), (r) =>
            _.pick(r, ["recommendationId", "latestRmclId", "latestRclId"])
          )
        )
      );
      setRecsToSendPerDocument(recsToSendPerDocumentTemp);
    }
  }, [groupedSelectedRecsWithJoinedOrRecs]);

  useEffect(() => {
    setDocumentErrorMessage(undefined);
    if (physicianValue && selectedFormat?.name === "fax") {
      if (reportGroupKeysOfPhysiciansToSkip.includes(physicianValue)) {
        setDocumentErrorMessage(
          `The ${physicianRole === "pcp" ? "primary care" : "referring"} physician${
            _.find(physiciansToSkip, { reportGroupKey: physicianValue }).reason
          }. This document cannot be sent.`
        );
      }
    }
  }, [physicianValue, selectedFormat, physiciansToSkip]);

  const recommendationIdsToRender = useMemo(() => {
    return _.flatMap(groupedSelectedRecsWithJoinedOrRecs?.[physicianValue], gettingAllRecIdsFromRec);
  }, [groupedSelectedRecsWithJoinedOrRecs, physicianValue]);

  useEffect(() => {
    if (selectedRecs?.length === 0) {
      setFormInfoMessage("Please select at least one recommendation to preview");
    } else {
      setFormInfoMessage(null);
    }
  }, [selectedRecs]);

  const { data: editableFields } = useQuery(
    ["fax", "editableFields", physicianValue, templateValue],
    () =>
      axios.get(API + "/documents/get-editable-fields-by-recommendation-ids", {
        params: {
          recommendationIds: recommendationIdsToRender,
          templateName: templateValue,
          format: selectedFormat.name,
          physicianRole,
        },
        paramsSerializer: {
          serialize: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
        },
        withCredentials: true,
      }),
    {
      select: (data) => data.data,
      enabled: recommendationIdsToRender.length > 0 && templateValue === "Fax template",
      refetchOnWindowFocus: false,
      refetchInterval: false,
    }
  );

  useEffect(() => {
    const sampleReport = groupedReports?.[physicianValue]?.[0];
    const unparsedFaxNumber = sampleReport?.[physicianRole].facility?.faxNumber;
    setFaxNumber(_.isString(unparsedFaxNumber) ? unparsedFaxNumber : null);
  }, [groupedReports, physicianValue]);

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const onCancelEdit = () => {
    setEditMode(false);
    setEditableFieldsToSend(null);
  };

  const {
    data: compiledPdf,
    isError: isErrorCompiledPdf,
    refetch: refetchCompiledPdf,
    isFetching: isFetchingCompiledPdf,
  } = useQuery(
    ["fax", "compiledPdf", physicianValue, templateValue],
    () =>
      axios.post(
        API + "/documents/compile-communication-letter-pdf-from-recommendation-ids",
        {
          recommendationIds: recommendationIdsToRender,
          templateName: templateValue,
          editMode,
          editableFields: editableFieldsToSend,
          physicianRole,
        },
        { withCredentials: true }
      ),
    {
      select: (data) => data.data,
      //This does not disable refetching!
      enabled: recommendationIdsToRender?.length > 0 && templateValue === "Fax template",
      refetchOnWindowFocus: false,
      refetchInterval: false,
    }
  );

  const {
    data: compiledText,
    isError: isErrorCompiledText,
    refetch: refetchCompiledText,
    isFetching: isFetchingCompiledText,
  } = useQuery(
    ["text", "compileText", physicianValue, templateValue],
    () =>
      axios.post(
        API + "/documents/compile-communication-letter-text",
        {
          templateName: templateValue,
          recommendationIds: recommendationIdsToRender,
          editMode,
          format: selectedFormat.name,
          editableFields: editableFieldsToSend,
          physicianRole,
        },
        { withCredentials: true }
      ),
    {
      select: (data) => data.data,
      enabled: recommendationIdsToRender.length > 0 && templateValue === "Text template",
      refetchOnWindowFocus: false,
      refetchInterval: false,
    }
  );

  useEffect(() => {
    // This condition is needed since the "enabled" property on useQuery does not disable refetch function from running
    if (recommendationIdsToRender?.length > 0 && templateValue === "Fax template") {
      // Refetch is done when edit mode changes. When exiting edit mode, refetch is done to update the pdf with
      // the editable fields. When entering edit mode, if some of the editable fields are of type "text", we need
      // to refetch pdf to have those field highlighted. If not, no need to refetch when entering edit mode
      if (editMode && !hasFieldOfType(editableFields, "text")) {
        return;
      }
      refetchCompiledPdf();
    }
  }, [editMode, editableFields]);

  // Group reports and recommendations by document, currently criteria: referring physician, facility
  if (!formats || !selectedFormat || !groupedSelectedRecsWithJoinedOrRecs) {
    return <></>;
  }

  function handleSelectedFormat(fmt) {
    setSelectedFormat(fmt);
    setEditableFieldsToSend(null);
    setEditMode(false);
  }

  function handleCloseCheckboxOnChange(e) {
    setIsCloseTicketChecked(e.target.checked);
  }

  function hasFieldOfType(editableFields, type) {
    return _.some(editableFields, (field) => _.get(field, "type") === type);
  }

  return (
    <Dialog maxWidth="lg" fullWidth scroll="paper" onClose={onClose} open={open} data-testid={"communication-modal"}>
      <DialogTitle>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Stack direction="row" spacing={2} sx={{ alignItems: "stretch" }}>
            {formats.length > 1 && (
              <ToggleButtonGroup
                exclusive
                sx={{
                  borderRadius: 1,
                  backgroundColor: "sideSecondary.secondary",
                }}
              >
                {formats.map((fmt) => {
                  if (fmt.name === "fax") {
                    return (
                      <Tooltip arrow title="Generate PDF documents and send faxes" key={fmt.name}>
                        <ToggleButton
                          value={fmt.name}
                          onClick={() => handleSelectedFormat(fmt)}
                          selected={selectedFormat.name === "fax"}
                          sx={{ border: "0px" }}
                        >
                          <FaxIcon />
                        </ToggleButton>
                      </Tooltip>
                    );
                  }
                  if (fmt.name === "text") {
                    return (
                      <Tooltip arrow title="Generate plain text to copy and use elsewhere" key={fmt.name}>
                        <ToggleButton
                          value={fmt.name}
                          selected={selectedFormat.name === "text"}
                          onClick={() => handleSelectedFormat(fmt)}
                          sx={{ border: "0px" }}
                        >
                          <NotesIcon />
                        </ToggleButton>
                      </Tooltip>
                    );
                  }
                })}
              </ToggleButtonGroup>
            )}
            {selectedFormat.templates && selectedFormat.templates.length > 1 && (
              <Select
                defaultValue={selectedFormat.templates[0]}
                input={<Input disableUnderline />}
                value={templateValue}
                variant="outlined"
                onChange={handleTemplateChange}
              >
                {selectedFormat.templates.map((key) => (
                  <MenuItem key={`template-${key.name}`} value={key.name}>
                    {key.name}
                  </MenuItem>
                ))}
              </Select>
            )}
            <Select
              defaultValue={groupsKeys[0]}
              input={<Input disableUnderline />}
              value={physicianValue}
              variant="outlined"
              onChange={handleRpChange}
            >
              {groupsKeys.map((key) => (
                <MenuItem key={`physician-${key}`} value={key}>
                  <Stack direction="row" spacing="4">
                    <Typography>
                      {titleCase(
                        `${groupedReports[key][0][physicianRole].firstName} ${groupedReports[key][0][physicianRole].lastName}`
                      )}
                    </Typography>
                    {reportGroupKeysOfPhysiciansToSkip.includes(key) ? <ErrorOutlineIcon /> : null}
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          <Collapse unmountOnExit in={Boolean(infoMessage)}>
            <Alert severity="info">{infoMessage}</Alert>
          </Collapse>
          <Collapse unmountOnExit in={Boolean(errorMessage)}>
            <Alert severity="error">{errorMessage}</Alert>
          </Collapse>

          <Stack direction="row" spacing={2}>
            <Box sx={{ flexShrink: 0 }}>
              {templateValue === "Text template" ? (
                <PreviewText
                  {...{
                    setErrorMessage,
                    compiledText,
                    isFetchingCompiledText,
                    isErrorCompiledText,
                  }}
                />
              ) : (
                <PreviewFax
                  {...{
                    recommendationIds: recommendationIdsToRender,
                    setErrorMessage,
                    compiledPdf,
                    isErrorCompiledPdf,
                    refetchCompiledPdf,
                    editableFields: editableFieldsToSend,
                    editMode,
                    templateName: templateValue,
                    isFetchingCompiledPdf,
                    physicianRole,
                  }}
                />
              )}
            </Box>
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Collapse unmountOnExit in={Boolean(documentErrorMessage)}>
                <Alert severity="error">{documentErrorMessage}</Alert>
              </Collapse>
              <Collapse unmountOnExit in={Boolean(formInfoMessage)}>
                <Alert severity="info">{formInfoMessage}</Alert>
              </Collapse>
              {faxNumber && selectedFormat?.name === "fax" && (
                <Card variant="outlined">
                  <Box sx={{ p: 2 }}>
                    <>
                      <Typography variant="subtitle2">Physician fax number</Typography>
                      <Stack direction="row" spacing={1}>
                        <Typography>{parsePhoneNumber(faxNumber, "US").formatNational()}</Typography>
                      </Stack>
                    </>
                  </Box>
                </Card>
              )}
              <Card variant="outlined">
                <Box sx={{ p: 2 }}>
                  {groupedReports[physicianValue]?.map((report) => (
                    <React.Fragment key={report.reportTextId}>
                      <Typography variant={"subtitle2"}>Patient</Typography>
                      <Typography>{`${titleCase(report.patientFirstName)} ${titleCase(
                        report.patientLastName
                      )}`}</Typography>

                      <Box sx={{ pt: 2 }}>
                        <Typography variant={"subtitle2"}>Recommendations</Typography>
                        <List>
                          {report.recommendations.map((rec) => {
                            return (
                              <FormGroup key={`checkbox-${rec.id}`}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={_.some(selectedRecs, { id: rec.id })}
                                      onChange={() => handleCheckboxOnChange(report, rec)}
                                      edge="start"
                                      tabIndex={-1}
                                      disableRipple
                                      disabled
                                    />
                                  }
                                  label={
                                    <>
                                      {rec.recommendedAction?.categories?.actionType === "Unspecified"
                                        ? rec.recommendedAction?.categories?.actionType
                                        : rec.recommendedAction.categories?.actionSubType}
                                      {rec.joinedOrRecs?.map((jRec) => (
                                        <React.Fragment key={jRec}>
                                          /
                                          {jRec.recommendedAction?.categories?.actionType === "Unspecified"
                                            ? jRec.recommendedAction?.categories?.actionType
                                            : jRec.recommendedAction.categories?.actionSubType}
                                        </React.Fragment>
                                      ))}
                                    </>
                                  }
                                />
                              </FormGroup>
                            );
                          })}
                        </List>
                      </Box>
                    </React.Fragment>
                  ))}

                  {hasFieldOfType(editableFields, "text") && editMode && (
                    <Box sx={{ pt: 1 }}>
                      <form>
                        <Stack gap={2}>
                          {Object.entries(editableFields).map(([key, fields]) => {
                            return (
                              fields.type === "text" && (
                                <Box>
                                  <Typography variant="subtitle2">{_.capitalize(_.startCase(key))}</Typography>
                                  <TextField
                                    name={key}
                                    defaultValue={fields.value}
                                    variant="outlined"
                                    multiline
                                    placeholder={`Insert ${_.lowerCase(_.startCase(key))}...`}
                                    fullWidth
                                    value={editableFieldsToSend?.[key]?.value}
                                    onChange={(e) =>
                                      handleEditableFieldsChange(e.target.name, fields.type, e.target.value)
                                    }
                                  />
                                </Box>
                              )
                            );
                          })}
                        </Stack>
                      </form>
                    </Box>
                  )}
                </Box>
              </Card>
              {editMode && selectedFormat.name === "fax" && hasFieldOfType(editableFields, "html") && (
                <Stack gap={2}>
                  {Object.entries(editableFields).map(([key, fields]) => {
                    return (
                      fields.type === "html" && (
                        <Card variant="outlined">
                          <Editor
                            key={key}
                            value={editableFieldsToSend?.[key]?.value ?? fields.value}
                            onChange={(newHtml) => handleEditableFieldsChange(key, fields.type, newHtml)}
                          />
                        </Card>
                      )
                    );
                  })}
                </Stack>
              )}
              {selectedFormat.name === "fax" && !isBatchCommunication && !editMode && (
                <Card variant="outlined">
                  <Box sx={{ p: 2 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCloseTicketChecked}
                          onChange={handleCloseCheckboxOnChange}
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                        />
                      }
                      label={"Close on successful send"}
                    />
                    <FormGroup key={`close`}></FormGroup>
                    <Collapse in={isCloseTicketChecked}>
                      <Box sx={{ mt: 1 }}>
                        <StatusClosedFields
                          formVarsForFields={{ control, errors, watch, resetField }}
                          selectedStatus={"closed"}
                        />
                      </Box>
                    </Collapse>
                  </Box>
                </Card>
              )}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>

      {selectedFormat.name === "fax" && (
        <DialogActions>
          <>
            {!editMode && (
              <LoadingButton disabled={_.isEmpty(editableFields)} onClick={handleEditMode} variant="outlined">
                Edit
              </LoadingButton>
            )}
            {editMode && (
              <>
                <Tooltip title="Discard all changes">
                  <IconButton onClick={onCancelEdit}>
                    <TrashIcon />
                  </IconButton>
                </Tooltip>
                <LoadingButton
                  disabled={selectedRecs?.length === 0}
                  loading={isFetchingCompiledPdf}
                  onClick={handleEditMode}
                  variant="contained"
                >
                  Preview
                </LoadingButton>
              </>
            )}
            <LoadingButton
              disabled={recsToSendPerDocument?.length === 0 || isFetchingCompiledPdf || editMode}
              loading={isLoadingSendFax}
              onClick={isCloseTicketChecked ? handleSubmit(handleSendFax) : handleSendFax}
              variant="contained"
              data-testid="send-fax-button"
            >
              Send {recsToSendPerDocument?.length > 0 ? `(${recsToSendPerDocument?.length})` : null}
            </LoadingButton>
          </>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CommunicateDialog;
